import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'InstantUC-loader',
  template: `
  <div class="preloader" *ngIf="loader">
            <div class="loading">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
    </div>
  `,
  styleUrls:['./loader.css'],
})
export class loaderComponent implements OnInit {
  @Input('show') loader:boolean;
  constructor() {
   }

  ngOnInit(){

  }
}