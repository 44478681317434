import { Injectable, Injector } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Observable } from 'rxjs';
import { InstantUcApiModels } from '../interface/Api/i.api-response';
import { IAppointmentMTService } from '../interface/appointment/i.appointment-method';
@Injectable({ providedIn: 'root' })

export class AppointmentService implements IAppointmentMTService {

    apiController: string = "api/Appointment"
    httpSerive: HttpService;

    constructor(injector: Injector) {
        this.httpSerive = injector.get(HttpService);
    }

    getAppointmentType(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
        let url = `${this.apiController}/GetAppointmentTypes`;
        return this.httpSerive.post(url, body);
    }

    bookAppointmentAllInOne(body):Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url = `api/AllInOne/BookInOneGo`;
        return this.httpSerive.post(url, body);
    }


    getAppointmentslot(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
        let url = `${this.apiController}/GetOpenAppointmentslotsFromLocal`;
        return this.httpSerive.post(url, body);
    }

    IsCheckInLinkExpired(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
        let url = `${this.apiController}/IsCheckInLinkExpired`;
        return this.httpSerive.post(url, body);
    }

    SaveCheckInStatus(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
        let url = `${this.apiController}/SaveCheckInStatus`;
        return this.httpSerive.post(url, body);
    }

    bookAppointment(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
        let url = `${this.apiController}/BookAppointment`;
        return this.httpSerive.post(url, body);
    }

    startCheckin(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
        let url = `${this.apiController}/StartCheckIn`;
        return this.httpSerive.post(url, body);
    }


    Checkedin(body):Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url=`${this.apiController}/CheckIn`;
        return this.httpSerive.post(url,body);
    }

    GetDepartmentDetails(body):Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url=`api/AdminDepartment/GetDepartmentInfo/`;
        return this.httpSerive.post(url,body);
    }
    GetDepartmentDetailsByCode(body):Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url=`api/AdminDepartment/GetDepartmentInfoByCode/`;
        return this.httpSerive.post(url,body);
    }
    GetFeatures(body):Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url=`api/AdminCommon/GetFeatures/`;
        return this.httpSerive.post(url,body);
    }


    GetAppointmentTypesFromLocal(body):Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url=`${this.apiController}/GetAppointmentTypesFromLocal/`;
        return this.httpSerive.post(url,body);
    }

    GetPatientInsuranceDetails(body):Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url=`${this.apiController}/GetPatientInsuranceDetails/`;
        return this.httpSerive.post(url,body);
    }

    GetAppointmentSetting(body): Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url= `api/AdminCommon/GetAppointmentSetting`;
        return this.httpSerive.post(url,body);
    }

    GetAppointmentDetails(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
        let url = `${this.apiController}/GetAppointmentDetails`;
        return this.httpSerive.post(url, body);
    }

    GetScreenElements(body): Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url= `api/ScreenSetting/GetScreenElements`;
        return this.httpSerive.post(url,body);
    }

    CheckIsAppointmentSlotLocked(body): Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url=  `${this.apiController}/CheckIsAppointmentSlotLocked`;
        return this.httpSerive.post(url,body);
    }

    UpdateAppointmentSlotLockingPeriod(body): Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url=  `${this.apiController}/UpdateAppointmentSlotLockingPeriod`;
        return this.httpSerive.post(url,body);
    }

    GetVisitPrice(body): Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url=  `${this.apiController}/GetVisitPrice`;
        return this.httpSerive.post(url,body);
    }

    UploadPatientForm(body): Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url=  `${this.apiController}/UploadPatientForm`;
        return this.httpSerive.post(url,body);
    }
 
    
    CancelAppointment(body): Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url=  `${this.apiController}/CancelAppointment`;
        return this.httpSerive.post(url,body);
    }

    ReScheduleAppointment(body): Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url=  `${this.apiController}/ReScheduleAppointment`;
        return this.httpSerive.post(url,body);
    }

    GetReasonForVisit(body): Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url=  `${this.apiController}/GetReasonForVisit`;
        return this.httpSerive.post(url,body);
    }

    UpdateAppointmentNotes(body){
        let url = `${this.apiController}/UpdateAppointmentNotes`;
        return this.httpSerive.post(url,body);
    }

    GetTelemedicineAppointmentDetails(body){
        let url = `${this.apiController}/GetTelemedicineAppointmentDetails`;
        return this.httpSerive.post(url,body);
    }

    UpdateTelemedicineAppointmentStatus(body){
        let url = `${this.apiController}/UpdateTelemedicineAppointmentStatus`;
        return this.httpSerive.post(url,body);
    }
    
    BookInOneGo_secondstep(body){
        let url = `api/AllInOne/BookInOneGo_secondstep`;
        return this.httpSerive.post(url,body);
    }

    UpdateProviderWorkStatus(body): Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url= `${this.apiController}/UpdateProviderWorkStatus`;
        return this.httpSerive.post(url,body);
    }

    UpdatePatientBackgroundStatus(body): Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url= `${this.apiController}/UpdateUserBackgroundStatus/`;
        return this.httpSerive.post(url,body);
    }
    
    
    RegisterCallback(body):Observable<any>{
        const url = `api/Patient/SendInCallMessage`;
        return this.httpSerive.post(url,body);
    }
    AddNoteForAppointment(body):Observable<any>{
        const url = `api/Patient/AddPatientNote`;
        return this.httpSerive.post(url,body);
    }
    AddFeedbackForAppointment(body):Observable<any>{
        const url = `api/Provider/AddProviderRating`;
        return this.httpSerive.post(url,body);
    }

    GetFAQ(body): Observable<InstantUcApiModels.InstantUcApiResponse>{
        let url = `api/UrgentCare/GetDepartmentWiseFAQ`;
        return this.httpSerive.postHq(url , body);
       }

       GetPatientStories(body):Observable<any>{
        let url = `api/Patient/GetPatientReviewsFeedback`;
        return this.httpSerive.post(url ,body);
       }

}


















