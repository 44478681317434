import { env } from 'process';
import { HttpService } from '../../../services/http/http.service';
import { GlobalService } from '../../../services/data-service/global.service';
import { Component, OnInit, Input,  } from "@angular/core";
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { IAppointmentMTService } from "../../../services/interface/appointment/i.appointment-method";
import {
  IgetAppointmentType,
  IgetAppointmentTimeSlot,
  IgetAppointmentTypeLocal,
} from "../../../services/interface/appointment/I.appointment-data";

import { environment } from "@env";

import { TosterService } from "@toster";
declare var $: any;
declare var gtag;
import {SitevisitorService} from '../../../services/api-services/site-visitor-service';
import { isPlatformBrowser } from "@angular/common";
import { Inject, PLATFORM_ID } from "@angular/core";

@Component({
  selector: 'Instant-UC-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.scss']
})
export class VisitComponent implements OnInit {

  appointmentType: Array<any> = [];
  appointmentslot: Array<any>[];
  finalList: Array<any> = [];
  seletedAppointmentType: any;
  dateCount: number = 0;
  DepartmentInfo: any;
  selectedApptType: any = null;
  SlotDetails: any;
 
  FeaturesInfo: any;
  IsAptBlocked: boolean = false;
  slotlength: number;
  isPageLoad: boolean = false;
 
  selectAppointmentPopupVisiblity: boolean = false;

  currentDate = new Date();
  selectedDate1 = new Date();
  nextSlotDate:string="";
  loader:boolean = true;
  maximumDate:any;

  
  @Input('DEPARTMENT_ID') Department_Id: number;
  @Input('PRACTICE_ID') Practice_Id: number;
  @Input('UniqueCode') uniqueCode:string;
 @Input('Environment') environmentObj;
  constructor(
    private httpService: HttpService,
    public iAppointmentMTService: IAppointmentMTService,
    public tosterService: TosterService,
    private sitevisitorService:SitevisitorService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformID: Object
  ) {
  
   
   
  }

  ngOnChanges(){
    console.log(this.Department_Id,this.Practice_Id)
    if (this.Department_Id) {
      this.getAppointment();
      this.addVisitors();
    } else {
      this.router.navigateByUrl("/");
    }
  }

  ngOnInit() {
    let DepartmentInfo:any
    if(isPlatformBrowser(this.platformID)) {
       DepartmentInfo = JSON.parse(localStorage.getItem("InstantUCDpInfo"));
    }
    let date = new Date();
    this.maximumDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    console.log(this.maximumDate,"max date----",this.maximumDate.getDate())

  }

  addVisitors = ()=>{
    let data ={
      PracticeId :this.Practice_Id,       
      DepartmentId :this.Department_Id,
      Visitors :  '',
      BrowserIp: '',
      BrowserDetails :''
      };
      this.sitevisitorService.saveSiteVisitorUsers(data).subscribe({
          next: (reuslt)=>{
              
          },
          error:(error)=>{
              console.log(error)
          }
      })
  } 

  GetDepartmentDetails(Department_ID) {
   
    let reqest = {
      Username: environment.USER_NAME,
      DepartmentId: Department_ID,
      PracticeId: this.Practice_Id,
    };
    // this.iAppointmentMTService.GetDepartmentDetails(reqest).subscribe(
    this.iAppointmentMTService.GetDepartmentDetails(reqest).subscribe(
      (res) => {
        this.DepartmentInfo = res.Data[0];
        this.getAppointment();
        if(isPlatformBrowser(this.platformID)){
        localStorage.setItem(
          "InstantUCDpInfo",
          JSON.stringify(this.DepartmentInfo)
        );
        }
      },
      (error) => { }
    );
  }

  ngAfterViewInit() {
    this.javascriptService();
  }

 

  getAppointment() {
    let details:any;
    if(isPlatformBrowser(this.platformID)){
     details = JSON.parse(localStorage.getItem('InstantUCPatientInfo'));
    }
    let req: IgetAppointmentTypeLocal = {
      Username: details ? details['Username'] : '',
      PracticeId: this.Practice_Id,
      DepartmentId: this.Department_Id,
      Id: 0,
    };
    this.iAppointmentMTService.GetAppointmentTypesFromLocal(req).subscribe(
      (res: any) => {
        this.appointmentType = res.Data.filter( item=> {
          if(item.IsBookingEnabled>0){
            return item;
           }
        });
        if(res.Data.length > 0){
        this.getAppointmentslots(res.Data[0].AppointmentTypeId);
        this.selectedApptType = `${res.Data[0].AppointmentTypeId},${res.Data[0].LabelName}`;
        }
        else{
          this.loader = false;
        }
        // this.isPageLoad = true;
      },
      (error) => {
        this.isPageLoad = true;
        if (error.error.ErrorDetails == "Appointments Booking is disabled") {
          this.IsAptBlocked = true;
        }
      }
    );
  }

  GTMtrackEvent(slot){
    gtag('event', 'CLICK_CONFIRM_SLOT', {
      'event_category': 'CLICK_CONFIRM_BUTTON',
      'event_label': `user click slot at ${slot.date} ${slot.time}`,
      'value': `Put a value here that is meaningful with respect to the click event`});
      
   }

  CheckAvailability(slot) {
    let details:any;
    if(isPlatformBrowser(this.platformID)){
    details = JSON.parse(localStorage.getItem('InstantUCPatientInfo'));
    }
    let reqObj = {
      AppointmentId: slot.appointmentid,
      Username: details ? details['Username'] : '',
      LoggedInUserId: 0,
    };
  ;
    this.iAppointmentMTService.CheckIsAppointmentSlotLocked(reqObj).subscribe(
      (res: any) => {
        this.SlotDetails = res.Data;
        if (res.Data.Status == true) {
          this.goToRegistrationPage(slot);
          if(isPlatformBrowser(this.platformID)){
          window.scroll(0, 0);
          }
        } else {
          this.tosterService.error(
            "This appointment is already in use.Please choose slot again."
          );
          this.getAppointmentslots(this.selectedApptType.split(",")[0]);
        }
      },
      (error) => { 
        this.loader = false;
      }
    );
  }

  onChangeAppointType(event) {
    this.nextSlotDate="";
    let Obj = event.split(",");
    this.selectedApptType = `${Obj[0]},${Obj[1]}`;
    this.getAppointmentslots(Obj[0]);
  }

  getAppointmentslots(appointType_id) {
    if(isPlatformBrowser(this.platformID)){
    let el: any = document.getElementById("more-btn");
    if (el) {
      this.resetData();
    }
    }
    let details:any;
    if(isPlatformBrowser(this.platformID)){
       let details = JSON.parse(localStorage.getItem('InstantUCPatientInfo'));
    }
    let req: IgetAppointmentTimeSlot = {
      PracticeId: this.Practice_Id,
      DepartmentId: this.Department_Id,
      AppointmentTypeId: appointType_id,
      Username: details ? details['Username'] : '',
    };
    this.loader = true;
    this.iAppointmentMTService.getAppointmentslot(req).subscribe((res: any) => {
      this.appointmentslot = [];
      this.appointmentSlots = {};

      


      //Experimental Code By Dharmesh
      if (res.Data.appointments.length > 0) {
        let i = 0;
        res.Data.appointments.forEach((el) => {

          if (!this.appointmentSlots[el.date]) {
            this.appointmentSlots[el.date] = [];
            this.availableAppointmentDates.push(el.date);
          }
          this.appointmentSlots[el.date].push(el);
        });
        this.selectedDate = res.Data.appointments[0].date;
        this.selectedDateIndex = 0;
      } else {
        let myDate = new Date();
        this.selectedDate = `${myDate.getMonth() + 1
          }/${myDate.getDate()}/${myDate.getFullYear()}`;
      }
      // End of Experimental Code
      let dataList = res.Data.appointments.map((dateList) => {
        return dateList.date;
      });
      let Dateset = new Set(dataList);

      this.finalList = [];
      Dateset.forEach(
        (element: any) => {
          let obj = {
            Date: element,
            slottime: res.Data.appointments.filter((slot) => {
              if (slot.date == element) {
                return slot;
              }
            }),
          };
          this.finalList.push(obj);
          this.slotlength = this.finalList.length;
          this.loader = false;
        },
        (error) => {
          this.isPageLoad = true;
          this.loader = false;
          if (error.error.ErrorDetails == "Appointments Booking is disabled") {
            this.IsAptBlocked = true;
          }
        }
      );
      this.findNextSlotDate();
      this.isPageLoad = true;
      this.loader = false;
    }, error=>{
      this.loader = false;
      this.tosterService.error("Something went wrong! please try again.")
    });
  }

  goToRegistrationPage(slot) {
    console.log(this.environmentObj.FolderName,"====foldername")
    let Obj = this.selectedApptType.split(",");
    let navigationExtras: NavigationExtras = {
      queryParams: {
        at_id: Obj[0],
        a_id: slot.appointmentid,
        starttime: slot.starttime,
        app_date: slot.date,
        app_type: Obj[1],
        providername: slot.providername,
        Udc: this.uniqueCode,
        did: this.Department_Id,
        pid: this.Practice_Id,
        providerid: slot.providerid
      },
    };
    let encriptUrl = btoa(JSON.stringify(navigationExtras));
    this.router.navigateByUrl(
      `/${this.environmentObj.FolderName}/appointment/book-appointment?data=` + encriptUrl
    );
    if(isPlatformBrowser(this.platformID)){
    window.scroll(0, 0);
    }
  }

  javascriptService() {
    if(isPlatformBrowser(this.platformID)){
      $(document).ready(function () {
        $(".booking-form .items").each(function (e) {
          if (e != 0) $(this).hide();
        });
        
      });
  }
  }

  onselectTime(e,slot?) {
    if(isPlatformBrowser(this.platformID)){
    this.GTMtrackEvent(slot);
    $(".time_button-Wrapper  button").click(function (e) {
      e.preventDefault();
      $(".time_button-Wrapper  button").removeClass("selected");
      $(this).addClass("selected");
    });

    $(".showLess").click(function () {
      $(".booking-form .items").removeClass("card-active");
      $(".loadMore").show();
      $(".showLess").hide();
    });
  }
  }

  showMoreOption() {
    if(isPlatformBrowser(this.platformID)){
    gtag('event', 'CLICK', {
      'event_category': 'CLICK_VIEW_MORE_SLOT',
      'event_label': `user click view more button`,
      'value': `Put a value here that is meaningful with respect to the click event`});
    $(".ap-slot-right .items").addClass("card-active");
    $(".showLess").css("display", "inline-block");
    $(".loadMore").hide();
    }
  }

  onClickLessBtn() {
    if(isPlatformBrowser(this.platformID)){
    $(".ap-slot-right .items").removeClass("card-active");
    $(".showLess").css("display", "none");
    $(".loadMore").show();
    $("html, body").animate({ scrollTop: 0 }, "slow");
    }

  }
  // rewrite Changes for calendar controls
  appointmentSlots = {};
  selectedDate = "";
  selectedDateIndex = 0;
  availableAppointmentDates = [];

  // handleNext() {

  //   if (this.selectedDateIndex + 1 < this.availableAppointmentDates.length) {
  //     this.selectedDateIndex += 1;
  //     this.selectedDate =
  //       this.availableAppointmentDates[this.selectedDateIndex];
  //     this.selectedDate1 = new Date(this.selectedDate);
  //   }
  // }

  checkAvailableSlot(date) {
    let newDate = `${date.month + 1 < 10 ? `0${date.month + 1}` : date.month + 1}/${date.day < 10 ? `0${date.day}` : date.day}/${date.year}`;
 

    let isAvailable = this.availableAppointmentDates.indexOf(newDate);
    if (isAvailable != -1) {
      return true
    }
    return false;
  }

  

  handlePrevious() {
        
        let temp = new Date(+new Date(this.selectedDate)-(1000*60*60*24));
        this.selectedDate  = `${temp.getMonth()+1>9?temp.getMonth()+1:`0${temp.getMonth()+1}`}/${temp.getDate()>9?temp.getDate():`0${temp.getDate()}`}/${temp.getFullYear()}`
        this.selectedDate1 = new Date(this.selectedDate);
        this.findNextSlotDate();
      }
    handleNext() {
        let temp = new Date(+new Date(this.selectedDate)+(1000*60*60*24));
        this.selectedDate  = `${temp.getMonth()+1>9?temp.getMonth()+1:`0${temp.getMonth()+1}`}/${temp.getDate()>9?temp.getDate():`0${temp.getDate()}`}/${temp.getFullYear()}`
        this.selectedDate1 = new Date(this.selectedDate)
        this.findNextSlotDate()
      }
      findNextSlotDate(){
            this.nextSlotDate = "";
            for(let i=0;i<this.availableAppointmentDates.length;i++){
              if(new Date(this.selectedDate) < new Date(this.availableAppointmentDates[i]))
              {this.nextSlotDate  = this.availableAppointmentDates[i];break;}
            }
          }
  changeSelectedDate(event) {
    let date = new Date(event);
    let newDate = `${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}/${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}/${date.getFullYear()}`
    this.selectedDate = newDate//newDate.join("/");
    this.selectedDateIndex = this.availableAppointmentDates.indexOf(
      this.selectedDate
    );
    // event.target.value = "";
  }
  resetData() {
    $(".loadMore").show();
    $(".showLess").css("display", "none");
    this.availableAppointmentDates = [];
    this.selectedDate = "";
    this.selectedDateIndex = 0;
  }
  getDate(selectedDate: any) {
    let date = new Date(selectedDate);
    return `${date}`.slice(0, 10);
  }

}
