import { Component, Input, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {IAccountService} from '../../services/interface/account/i.account-method';
import sha1 from 'sha1'; 
import {TosterService} from '@toster';
import {GlobalService} from '@Globalservice';
import {environment} from '@env';
import { isPlatformBrowser } from "@angular/common";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isValidForm: boolean;
  @Input('close') close;
  @Input('askToclose') reset;
  isValidUser:boolean = false;
  siteKey:string;
  msgs:any=[];
  isPlateFormBw:boolean = false;
  constructor(
    private Fb:FormBuilder,
    private iAccountService:IAccountService,
    private tosterService:TosterService,
    private router:Router,
    private globalService:GlobalService,
    @Inject(PLATFORM_ID) public platformId: Object 
    ) {
    this.loginForm = this.Fb.group({
      "PracticeId": [0],
      "DepartmentId":[0],
      "Password":['',[Validators.required,Validators.pattern(new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})"))]],
      "recaptcha":['',[Validators.required]],
      "Username":['',[Validators.required]],
      })
  
   }

  ngOnInit() {
    this.siteKey = environment.siteKey;
    if (isPlatformBrowser(this.platformId)){
      this.isPlateFormBw = true;
    } else{
      this.isPlateFormBw = false;
    }
      
  }

  ngOnChanges(){
    if(this.reset==false){
      this.resetForm();
    }
  }

  onLogin(){
    if(this.loginForm.valid){
    let data = this.loginForm.value;
      data.Password = sha1(this.loginForm.controls['Password'].value);
      data.ClientIPAddress = Math.random();
      this.iAccountService.UserLogin(data).subscribe((res:any)=> {
        localStorage.setItem('InstantUCPatientInfo',JSON.stringify(res.Data));
        localStorage.setItem('bookingToken',res.Data.AuthToken);
        this.globalService.loginSubscribe$.next("loginTrue")
        this.close({token: res.Data.AuthToken, patientDetails:res.Data});
        this.resetForm();
        this.tosterService.success("login success");
        this.msgs=[];
      },
      error=>{
        if(error.status==400){
          // this.tosterService.error(error.error.ErrorDetails);
          this.msgs=[];
          this.msgs.push({severity:'error', summary:'Error', detail:error.error.ErrorDetails});
        }
      })
    }else{
      this.isValidForm = true;
    }
  }

  showResponse(event){
    console.log(event,"capcha event-------")
    this.isValidUser = true;
  }

  resetForm(){
    this.loginForm.reset();
    this.msgs=[];
    this.isValidForm = false;
  }

  handleSuccess(event){
    this.loginForm.controls['recaptcha'].setValue(event)
  }
  handleReset(event:any) {
    console.log(event,"-------------");
    this.loginForm.controls['recaptcha'].setValue('')
  }

  

}
