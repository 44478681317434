

    <div class="input-group custom_serch">
      <div class="input-group-prepend">
        <span id="basic-addon1" class="input-group-text pr-0">
          <i class="fa fa-map-marker"></i
        ></span>

      </div>
      
      <input 
        type="text"   autocomplete="off"
        class=""
        [formControl]="searchLocation"
        id="top-select-search"
        placeholder="Search & Select Location"
        [(ngModel)]="location" style="z-index: 2;" (keyup)="processChange()"/>
        
        <!-- <button type="button" onclick="processChange"></button> -->

      <p *ngIf="loader" >
        
        <Instant-UC-loader></Instant-UC-loader>
      </p>
      <ul id="top-select-search" class="custom_filter">
        <li *ngFor="let place of placeList; let i = index">
          <a (click)="selectLocation(place)" 
            ><i class="fa fa-map-marker"></i>{{ place?.formatted_address.split(",").splice(0,2).join(",") }}</a
          >
          </li>
        <li *ngIf="resultsData?.status == 'ZERO_RESULTS' && !isInvalidForm">No Data Found</li>
      </ul>
      
    </div>
    
   