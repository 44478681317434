import { Component, OnInit, Input,Output,EventEmitter} from '@angular/core';
import { environment } from '@env';
import { IEncounterService } from '../../../services/interface/encounter/I.encounter-method';


@Component({
  selector: 'Instant-UC-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  @Output() Confirm: EventEmitter<any> = new EventEmitter();
 display:boolean;
 name: any;
 allergyid:any;
 DepartmentInfo = JSON.parse(localStorage.getItem('InstantUCDpInfo'))

  constructor(
    public iEncounterService:IEncounterService
  ) {
  
   }

  ngOnInit() {
  }

 

  showModel(name){
   
    this.name = name;
    //this.allergyid=allergyid
    this.display = true;
  }

  hideModal(){
    this.display = false;
  }

  onClickConfirm(){
    this.Confirm.emit(true);
  }

 
}
