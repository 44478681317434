<header id="main-header" data-height-onload="74" data-height-loaded="true" data-fixed-height-onload="74"
  style="top: 0px" class="">
  <div class="container clearfix et_menu_container">
    <div class="row">
      <div class="col-lg-4 col-sm-4">
        <a routerLink="">
          <img src="assets/images/logo.png" alt="Practice Crown" id="logo" data-height-percentage="90"
            data-actual-width="350" data-actual-height="74" />
        </a>

        <a class="collapse-icon toggleclickbtn" id="toggleclickbtn">
          <svg _ngcontent-val-c72="" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
            viewBox="0 0 16 16" class="bi bi-list ng-star-inserted">
            <path _ngcontent-val-c72="" fill-rule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z">
            </path>
          </svg>
        </a>
      </div>
      <div class="col-lg-8 col-sm-8">
        <div id="et-top-navigation" data-height="60" data-fixed-height="60">
          <nav id="top-menu-nav">
            <ul id="top-menu" class="nav">
             
              <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1011">
                <a routerLink="/find-a-doctor" (click)="GTMTractEvent()">Find a Doctor</a>
              </li>

            
              <li class="menu-item menu-item-type-custom dropdown">
                <a href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">For
                  Providers <i class="fa fa-angle-down" aria-hidden="true"></i></a>
                <ul class="dropdown-menu sub-menu pcmenuu">
                  <div class="row no-gutter">
                    <div class="col-sm-4 hide-on-mobile">
                      <img width="20" height="20" src="assets/img/nav-icon/nav-image.png"  alt="One Stop For All The
                      Digital Needs Of Your Practice">
                      <h4 class="text-center">One Stop For All The
                        Digital Needs Of Your Practice</h4>
                    </div>
                    
                    <div class="col-sm-4">
                      <li>
                        <a replaceUrl="true" href="/appointment-reminders" routerLink="/appointment-reminders">
                          <img width="20" height="20" src="assets/img/nav-icon/reminder.png" alt="Appointment Reminders">
                          Appointment
                          Reminders</a>
                      </li>
                     
                      <li>
                        <a replaceUrl="true" href="/digital-check-in" routerLink="/digital-check-in">
                          <img width="20" height="20" src="assets/img/nav-icon/digital-check-in.png" alt="Digital Check-In">
                          Digital Check-In</a>
    
                      </li>
                      
                      <li>
                        <a replaceUrl="true" href="/digital-marketing" routerLink="/digital-marketing">
                          <img width="20" height="20" src="assets/img/nav-icon/digital-marketing.png" alt=" Digital Marketing">
                          Digital Marketing</a>
                      </li>
                      <li>
                        <a replaceUrl="true" href="/website-development" routerLink="/inventory-management">
                          <img width="20" height="20" src="assets/img/nav-icon/inventory-nav-icon.png" alt="Inventory Management">
                          Inventory Management
                          </a>
                      </li>
                      <li>
                        <a replaceUrl="true" href="/marketing-campaigns" routerLink="/marketing-campaigns">
                          <img width="20" height="20" src="assets/img/nav-icon/marketing-camp.png" alt=" Marketing Campaigns">
                          Marketing
                          Campaigns</a>
                      </li>
                      <li>
                        <a replaceUrl="true" href="/online-appointment-booking"
                          routerLink="/online-appointment-booking">
                          <img width="20" height="20" src="assets/img/nav-icon/online-appointment.png" alt=" Online Appointment Booking">
                           Online Appointment Booking</a>
                      </li>
                     
                     
                     
                    </div>

                    <div class="col-sm-4">
                      <li>
                        <a replaceUrl="true" href="reputation-management" routerLink="/reputation-management">
                          <img src="assets/img/nav-icon/reputation.png" alt=" Reputation Management">
                          Reputation
                          Management</a>
                      </li>
                      <li>
                        <a replaceUrl="true" href="/search-engine-optimization"
                          routerLink="/search-engine-optimization">
                          <img src="assets/img/nav-icon/seo-search-symbol.png" alt="SEO">
                          SEO</a>
                      </li>
                      <li>
                        <a replaceUrl="true" href="/telemedicine" routerLink="/telemedicine">
                          <img src="assets/img/nav-icon/telemedicine.png" alt="Telemedicine">
                          Telemedicine</a>
                      </li>
                    
                      
                      <li>
                        <a replaceUrl="true" href="/text-messaging" routerLink="/text-messaging">
                          <img src="assets/img/nav-icon/text-message.png" alt="Text Messaging">
                          Text Messaging</a>
                      </li>
                      <li>
                        <a replaceUrl="true" href="/website-development" routerLink="/website-development">
                          <img src="assets/img/nav-icon/web-develp.png" alt="Website Development">
                          Website
                          Development</a>
                      </li>
                     
                    </div>
                   
                  </div>
                 
                </ul>
              </li>


              <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1011">
                <a routerLink="/business-location/login">Business Login</a>
              </li>
              <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1011">
                <a  routerLink="/on-boarding" (click)="GTMTractEvent()">Start Free Trial  </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <!-- <a class="toggleclickbtn">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                  </svg>
            </a> -->
    </div>

    <!-- #et-top-navigation -->
  </div>
  <!-- .container -->
  <!-- <div class="et_search_outer">
        <div class="container et_search_form_container">
            <form role="search" method="get" class="et-search-form" action="https://practicecrown.com/">
            <input type="search" class="et-search-field" placeholder="Search …" value="" name="s" title="Search for:">				</form>
            <span class="et_close_search_field"></span>
        </div>
    </div> -->
</header>
<!-- #main-header -->


<div class="overlay"></div>