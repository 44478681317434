<form [formGroup]="loginForm" class="login-box" (ngSubmit)="onLogin()">
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <div class="form-group">
        <p-messages [closable]="false" [(value)]="msgs"></p-messages>
      </div>
    </div>
    <div class="col-md-12 col-sm-12">
      <div class="form-group">
        <label>Username<span style="color: red">*</span></label>
        <input
          formControlName="Username"
          type="text"
          class="textbox form-control"
          name="uemail"
          id="uemail"
          [ngClass]="{
            'error-message':
              loginForm.controls['Username'].hasError('required') && isValidForm
          }"
        />
        <span class="highlight"></span>
        <span class="bar"></span>
        <p
          class="error-message-text"
          *ngIf="
            loginForm.controls['Username'].hasError('required') && isValidForm
          "
        >
          Username is Required.
        </p>
        <p
          class="error-message-text"
          *ngIf="
            loginForm.controls['Username'].hasError('pattern') && isValidForm
          "
        >
          Please enter a valid username.
        </p>
      </div>
    </div>
    <div class="col-md-12 col-sm-12">
      <div class="form-group">
        <label>Password <span style="color: red">*</span></label>
        <input
          formControlName="Password"
          type="password"
          class="textbox form-control"
          name="fname"
          id="fname"
          [ngClass]="{
            'error-message':
              loginForm.controls['Password'].hasError('required') && isValidForm
          }"
        />
        <span class="highlight"></span>
        <span class="bar"></span>
        <p
          class="error-message-text"
          *ngIf="
            loginForm.controls['Password'].hasError('required') && isValidForm
          "
        >
          Password is Required.
        </p>
      </div>
    </div>
    <div class="col-md-12 col-sm-12" *ngIf="isPlateFormBw">
      <div class="form-group">
        <div class="fxt-transformY-50 fxt-transition-delay-2">
          <ngx-recaptcha2
            #captchaElem
            (success)="handleSuccess($event)"
            (reset)="handleReset($event)"
            [siteKey]="siteKey"
            formControlName="recaptcha"
          >
          </ngx-recaptcha2>
          <label
            class="invalidField-message"
            *ngIf="
              loginForm.controls['recaptcha'].hasError('required') &&
              isValidForm
            "
          >
            Captcha required
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center">
    <input type="submit" class="btn btn-primary float-no" value="Log-in" />
    <div class="clearfix"></div>
  </div>
</form>
