import { environment } from "@env";
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  ChangeDetectorRef,
  Inject,
  PLATFORM_ID,
} from "@angular/core";
import { GlobalService } from "@Globalservice";
import { isPlatformBrowser } from "@angular/common";
declare var $: any;
@Component({
  selector: "Instant-UC-commondiv",
  templateUrl: "./commondiv.component.html",
  styleUrls: ["./commondiv.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommondivComponent implements OnInit, OnChanges {
  @Input("DepartmentDetails") department_details: any;
  @Input("isTemp") isTemp: any;
  images: any[];
  displayCustom: boolean;
  screen_size=false;
  @Input('IsSlider') isslider:any=true;
  activeIndex: number = 0;

  responsiveOptions: any[] = [
    {
      breakpoint: "1024px",
      numVisible: 1,
    },
    {
      breakpoint: "768px",
      numVisible: 1,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];
  depId: any = environment.DEP_ID;
  practiceId: any = environment.PRACTICE_ID;
  // DepartmentInfo = JSON.parse(localStorage.getItem('InstantUCDpInfo'))
  userLoginStatus: boolean = false;
  address: any;
  @Input("showLogin") showLogin: number = 1;
  constructor(
    private globalService: GlobalService,
    private ref: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isTemp = true;
    this.globalService.userLoginStatus.subscribe((res) => {
      console.error(res);
      this.userLoginStatus = res;
      this.ref.markForCheck();
    });
  }

  ngOnInit() {
    console.log(this.department_details);
    if (isPlatformBrowser(this.platformId)) {
      this.images = this.department_details.lstImages;
    }
    this.address = `${this.department_details?.Address1.replaceAll(
      " ",
      "+"
    )}+${this.department_details?.Address2.replaceAll(
      " ",
      "+"
    )}+${this.department_details?.City.replaceAll(
      " ",
      "+"
    )}+${this.department_details?.State.replaceAll(
      " ",
      "+"
    )}+${this.department_details?.ZIP.replaceAll(" ", "+")}`;
    console.log(this.address);

    setTimeout(function(){
      if ($('.banner-carousel').length) {
        $('.banner-carousel').owlCarousel({
          animateOut: 'fadeOut',
            animateIn: 'fadeIn',
          loop:true,
          margin:20,
          nav:false,
          dots:false,
          smartSpeed: 600,
          autoHeight: true,
          autoplay: true,
          navText: [ '<span class="fa fa-angle-left">', '<span class="fa fa-angle-right"> '],
          responsive:{
            0:{
              items:1
            },
            600:{
              items:2
            },
            1024:{
              items:2
            },
          }
        }); 
      }
    }, 3000);


    if(window.innerWidth<600){
      this.screen_size=false
   } else {
     this.screen_size=true;
   }
  }

  openLogin() {
    this.globalService.loginBox$.next(true);
  }

  ngOnChanges() {
    // console.log(this.department_details.GoogleMapLocationURL);
  }

  imageClick(image: any) {
    const index = this.images.indexOf(image);
    this.activeIndex = index;
    this.displayCustom = true;
  }
}
