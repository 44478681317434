

<p-dialog header="Confirmation Alert" modal="true" 
   (onHide)="hideModal()"
    [(visible)]="display" 
    [style]="{'width': '400px'}"
    [baseZIndex]="10000">
    <div style="padding: 30px;text-align: center;">
        <p>Are you sure you want to delete this {{name}}?</p>
    </div>
    <p-footer>
        <button type="button" pButton class="ui-button-danger" (click)="onClickConfirm()" label="Delete"></button>
        <button type="button" pButton (click)="display=false" label="Cancel"
            class="ui-button-secondary"></button>
    </p-footer>
</p-dialog>