import jwt_decode from "jwt-decode";
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { GlobalService } from "@Globalservice";
import { TosterService } from "@toster";
import { environment } from "@env";
import { isPlatformBrowser } from "@angular/common";
import { Inject, PLATFORM_ID } from "@angular/core";
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  loader: boolean;
  ;

  constructor(
    private tosterService: TosterService,
    public globalService: GlobalService,
    @Inject(PLATFORM_ID) private platformID: Object
  ) {
    // console.log(this.HqAuthToken, "HqAuthToken-------------");
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token: string;
    let practiceSiteData:any;
    const [authorization] = request.headers.keys();
    const [username] = request.headers.keys();
    console.log(authorization , username);
   
    if (isPlatformBrowser(this.platformID)) {
      token = localStorage.getItem("access_token");
      practiceSiteData= localStorage.getItem("practiceSiteData");
      
    }
    setTimeout(() => {
      this.globalService.loader = true;
    }, 50);
    if (token) {
      request = request.clone({
        headers: request.headers.set("Authorization", token),
      });
    }
    //  else if (HqAuthToken) {
    //   request = request.clone({
    //     headers: request.headers.set("Authorization",HqAuthToken),
    //   });
    //   request = request.clone({
    //     headers: request.headers.set("UserName" , UrgentCareLoginDetails.Username),
    //   });
    // }
   // else {
     // request = request.clone({
      //  headers: request.headers.set("UserName", "kshitij@3bstaffing.com"),
     // });

      // if (request.method == "GET" || request.method == "DELETE") {
      //   request = request.clone({
      //     headers: request.headers.set(
      //       "Authorization",
      //       "EV-OCT-EVENT-BIZBRO-2020LLY"
      //     ),
      //   });
      // }
      // else {
    
      if (authorization) {
        // request.clone({
        // headers: request.headers.set("Authorization" ,
        // request.headers['Authorization'])
        // })
      } else if (!practiceSiteData) {
        request = request.clone({
          headers: request.headers.set(
            "Authorization",
            "EV-OCT-EVENT-BIZBRO-2020LLY"
          ),
        });

        request = request.clone({headers: request.headers.set("UserName","")});
        console.log(request.headers["Authorization"]);
        
      } 
      else if(request.method == "GET" && practiceSiteData){
        // if (practiceSiteData) {
         
         request = request.clone({
           headers: request.headers.set("Authorization",this.getDecodedAccessToken(practiceSiteData).API_KEY)
             // request.body.apiKey == environment.API_BASE_PATH
             //   ? environment.API_KEY
             //   : request.body.apiKey
           //),
         });
         request = request.clone({headers: request.headers.set("UserName","")});

      //} 

        //}

        // if (request.body["apiKey"]) {
        //   request = request.clone({
        //     headers: request.headers.set(
        //       "Authorization",
        //       request.body.apiKey == environment.API_BASE_PATH
        //         ? environment.API_KEY
        //         : request.body.apiKey
        //     ),
        //   });
        // } else {
        //   request = request.clone({
        //     headers: request.headers.set(
        //       "Authorization",
        //       environment.API_BASE_PATH
        //     ),
        //   });
        // }
      } else{
        request = request.clone({
          headers: request.headers.set("Authorization",this.getDecodedAccessToken(practiceSiteData).API_KEY)
            // request.body.apiKey == environment.API_BASE_PATH
            //   ? environment.API_KEY
            //   : request.body.apiKey
          //),
        });
        request = request.clone({headers: request.headers.set("UserName","")});
      }
   // }
    request = request.clone({
      headers: request.headers.set("Accept", "application/json"),
    });
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        //

        if (event instanceof HttpResponse) {
          this.globalService.loader = false;
        }
        return event;
      }),

      catchError((error: HttpErrorResponse) => {
        this.globalService.loader = false;
        if (error.status == 0) {
          this.tosterService.error("Please check Your Internet connection ");
        }
        return throwError(error);
      })
    );
  }

  getDecodedAccessToken(practiceSiteData: string): any {
    try {
      return jwt_decode(practiceSiteData);
    } catch (Error) {
      console.log(Error);
      return null;
    }
  }
}
