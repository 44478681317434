import { Injectable, Injector } from '@angular/core';
import {HttpService} from '../http/http.service';

@Injectable(
    {
        providedIn:'root'
    }
)
export class BlogServices{
  
    controllerName = "api/Admin";
    controllerNameAssets = "assets/data";
    httpService: HttpService;

    constructor(injector:Injector) {
        this.httpService=injector.get(HttpService)
    }

    GetMasterService(body:any){
        let url= `${this.controllerName}/GetMasterServices`;
        return this.httpService.getHqWithoutAuthorized(url,body);
    }
    GetBlogDetails(body:any){
        let url= `${this.controllerName}/GetBolgsDetailsbyid/${body.ServiceId}`;
        return this.httpService.getHqWithoutAuthorized(url,body);
    }

}