import { HttpService } from './../../../services/http/http.service';
import { Component, OnInit, Input, ChangeDetectionStrategy, PLATFORM_ID, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IAppointmentMTService } from '../../../services/interface/appointment/i.appointment-method'
import { environment } from '@env';
import { isPlatformBrowser } from "@angular/common";
import { CountdownComponent } from 'ngx-countdown'
declare var $: any;
@Component({
  selector: 'Instant-UC-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountdownTimerComponent implements OnInit {
  @Input('appointmentDetails') appointmentInfo: any;
  @Input('redirectUrl') RedirectUrl = "AIO"
  startTime = new Date();
  confirmationModal: boolean;
  cancelBtnName = "Continue";
  departmentList: any;
  @ViewChild('cd') countdown;
  warningPopup: boolean = false;
  constructor(private iAppointmentMTService: IAppointmentMTService,
    private httpService: HttpService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router) {

  }

  ngOnInit() {

    // this.countdown.begin();
    // this.confirmationModal = true;
    // this.CheckIsAppointmentSlotLocked();
    this.startTime.setMinutes(this.startTime.getMinutes() + 4);
    this.departmentList = JSON.parse(localStorage.getItem('InstantUCDpInfo'));
  }

  CheckIsAppointmentSlotLocked() {
    let data = {
      "AppointmentId": this.appointmentInfo.a_id,
      "Username": environment.USER_NAME,
      "LoggedInUserId": 0
    }
    this.iAppointmentMTService.CheckIsAppointmentSlotLocked(data).subscribe(res => {

      if (res.Data.Status) {
        this.startTime.setMinutes(this.startTime.getMinutes() + 1);
        this.cancelBtnName = "Cancel";
      } else {
        this.confirmationModal = true;
        this.cancelBtnName = "Ok";
      }
    },
      error => {

      })
  }

  UpdateAppointmentSlotLockingPeriod() {
    let data = {
      "AppointmentId": this.appointmentInfo.a_id,
      "Username": environment.USER_NAME,
      "LoggedInUserId": 0
    }
    this.iAppointmentMTService.UpdateAppointmentSlotLockingPeriod(data).subscribe(res => {

      this.confirmationModal = false;
      this.startTime = new Date();
      this.startTime.setMinutes(this.startTime.getMinutes() + 4);
    },
      error => {
      })
  }

  triggerFunction(event) {
    console.log(this.countdown)
    console.log(event)
    if (event.action == 'done') {
      this.confirmationModal = true;
      if (this.RedirectUrl != "Qr") {
        localStorage.removeItem(`${this.appointmentInfo.a_id}`)

      }

      }

    
  }

  notifify(event) {
    console.log(event)

    if (event.action == 'done' && this.RedirectUrl=="Qr") {
      this.warningPopup = true;
      $('#notfound').modal('show');
    }
  }

  getName(name) {
    let PName = name.replace(/ /gi, "-");
    return (PName.toLowerCase());
  }

  onClickcancel() {
    
       let practiceInfo = JSON.parse(localStorage.getItem('InstantUCDpInfo'));
   
       if (practiceInfo.DepartmentId && practiceInfo.PracticeId && practiceInfo.UniqueDepartmentCode) {
         this.router.navigateByUrl(`/${this.httpService.getPrecticeName()}/${this.getName(practiceInfo.PracticeName)}-${this.getName(practiceInfo.City)}-${this.getName(practiceInfo.State)}-${this.getName(practiceInfo.UniqueDepartmentCode)}`)
       }
       else {
         this.router.navigateByUrl(`p/${practiceInfo.PracticeId}/d/${practiceInfo.DepartmentId}/appointment`);
       }

     
  }


}



