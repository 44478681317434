import { Observable } from 'rxjs';
import { InstantUcApiModels } from '../Api/i.api-response';

export abstract class IAppointmentMTService {
    public abstract getAppointmentType(Obj: any): Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract getAppointmentslot(Obj:any): Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract bookAppointment(Obj:any): Observable<InstantUcApiModels.InstantUcApiResponse>;

    public abstract bookAppointmentAllInOne(Obj:any): Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract BookInOneGo_secondstep(Obj:any): Observable<InstantUcApiModels.InstantUcApiResponse>;
    


    public abstract startCheckin(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract Checkedin(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract GetDepartmentDetails(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract GetDepartmentDetailsByCode(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract GetAppointmentTypesFromLocal(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract GetAppointmentSetting(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract GetAppointmentDetails(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract GetScreenElements(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract CheckIsAppointmentSlotLocked(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract UpdateAppointmentSlotLockingPeriod(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract GetPatientInsuranceDetails(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>
    public abstract GetVisitPrice(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>
    public abstract UploadPatientForm(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>
    public abstract GetFeatures(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>
    
    public abstract SaveCheckInStatus(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>
    public abstract IsCheckInLinkExpired(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>
    public abstract CancelAppointment(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>

    public abstract ReScheduleAppointment(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>
    public abstract GetReasonForVisit(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>
    public abstract UpdateAppointmentNotes(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>
    public abstract GetTelemedicineAppointmentDetails(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>
    public abstract UpdateTelemedicineAppointmentStatus(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>
    public abstract UpdateProviderWorkStatus(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>
    public abstract UpdatePatientBackgroundStatus(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>
    public abstract RegisterCallback(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>
    public abstract AddNoteForAppointment(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>
    public abstract AddFeedbackForAppointment(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>
    // public abstract AddPatientReviewRating(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>
    public abstract GetFAQ(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract GetPatientStories(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    }


