<!--<nav class="navbar navbar-expand-lg navbar-light">

    <a class="navbar-brand logo_h" href="#"><img src="assets/images/logo.png" alt=""></a>

</nav>-->




<!-- Code starts from here -->



<nav class="navbar navbar-expand-lg header-nav position-relative mb-0" [ngClass]="{'header-nav':isMedhealth}">
    <div class="navbar-header header-logo cursor-pointer" (click)="goToDepartmentWebSite()"
        [ngStyle]="{'backgroundImage': 'url('+departmentInfo?.Logo+')'}">

    </div>

    <ul class="nav header-navbar-rht right-side">

        <li *ngIf="access_token" class="nav-item contact-item d-flex ">
            <div class="user-name">
                <span><i class="fa fa-user"></i>{{userLogin?.FirstName}} </span>
            </div>
            
            <div class="avatar-profile" (click)="logout.toggle($event)">
                <img src="assets/img/user.jpg" alt="">
            </div>
        </li>
        <li *ngIf="(!access_token )&& (showLogin>0)" class="nav-item">
            <a class="nav-link header-login" (click)="loginPage=true;">login</a>
            
        </li>
    </ul>
</nav> 


<p-overlayPanel [ngStyle]="{'display':!access_token?'none':'block'}" #logout [showCloseIcon]="false" [style]="{width: 'auto'}"
  (onHide)="loginPage=false;">
    <ng-template pTemplate>
        <ul style=" text-transform: capitalize;">
            <li><i class="fa fa-user log-icon"></i> {{userLogin?.FirstName}} {{userLogin?.LastName}}</li>

            <li (click)="LogOut()" class="cursor-pointer">
                <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-right log-icon" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
                    <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                  </svg><a > Logout</a> </li>
        </ul>
    </ng-template>
</p-overlayPanel>

<p-dialog class="login" header="Login" [(visible)]="loginPage" position="top" [modal]="true" [style]="{width: '30vw'}"
    [draggable]="false" [resizable]="false">
    <div>
        <app-login *ngIf="loginPage" [close]="closeLoginPage" [askToclose]="loginPage"></app-login>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="loginPage=false;" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>