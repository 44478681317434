import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { InstantUcApiModels } from '../interface/Api/i.api-response';
import { Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
@Injectable({
  providedIn: 'root'
})
export class SitevisitorService {

  apiController: String = 'api/AdminCommon';
  httpSerive: HttpService;
  constructor(injector: Injector, @Inject(PLATFORM_ID) private platformID: Object) {
      this.httpSerive = injector.get(HttpService);
      
  }

  saveSiteVisitorUsers(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    body.BrowserDetails = this.getOS()
    const url = `${this.apiController}/Add_SiteVisitor`;
    return this.httpSerive.post(url, body);
  }

  getIPAddress() : Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = 'https://api.ipify.org?format=json';
    return this.httpSerive.getGoogle(url)
  }
 
  getOS = () => {
    if (isPlatformBrowser(this.platformID)){
        let userAgent = window.navigator.userAgent;
        let navigator: any = window.navigator;
        let platform =
            navigator?.userAgentData?.platform ?? window.navigator.platform,
          macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
          windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
          iosPlatforms = ["iPhone", "iPad", "iPod"],
          os = null;
    
        if (macosPlatforms.indexOf(platform) !== -1) {
          os = "Mac OS";
        } else if (iosPlatforms.indexOf(platform) !== -1) {
          os = "iOS";
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os = "Windows";
        } else if (/Android/.test(userAgent)) {
          os = "Android";
        } else if (!os && /Linux/.test(platform)) {
          os = "Linux";
        }
        return os;
      };

    }
}

