<div class="row">
   <div class="col-sm-12">
      <div class="camera-icon" (click)="startCamera()">
         <button class="button-round btnred">
            <i class="fa fa-camera" aria-hidden="true" title="Open Camera"></i><span>Take Photo</span>
         </button>
      </div>
   </div>
   <div class="camera-container camera-container{{sequence}}">

      <button class="close" (click)="offCamera();">X</button>

      <video #video class="vid mobile-camera" playsinline id="video-tag" style="width:100%;" autoplay> </video>
      <div class="crop-overlay" #cropOverlay></div>
      <!-- <div class="camera-content">
         <div class="face-area">
         </div>
         <div class="face-border">
         <li></li>
         <li></li>
         <li></li>
         <li></li>

         </div>
      </div> -->

      <i *ngIf="isCapture" (click)="captureCroppedImage()" class="fa fa-camera capature-image-btn" aria-hidden="true"></i>
      <!-- <button type="button" (click)="offCamera();">button</button> -->
   </div>
</div>
<div style="display:none;">
   <!-- <canvas #canvas class="vid" style="width:100%;"></canvas> -->
   <canvas #croppedCanvas style="display: none;"></canvas>
</div>



<p-dialog header="Photo" [(visible)]="captureModal" [modal]="true" [responsive]="true"
   [style]="{width: '350px', minWidth: '200px'}" [maximizable]="false" class="full-screen-modal custom-popup"
   [minY]="70" position="top" [maximizable]="false" [baseZIndex]="10000">

   <div class="container">

      <div>

         <image-cropper *ngIf="iamgeType=='DLIMAGE'" [imageBase64]="selectedImage?.priview"
            [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" style="width: 100%" format="png"
            [resizeToWidth]="650" [resizeToHeight]="350" (imageCropped)="onImageCropped($event)"
            (cropperReady)="cropperReady($event)">
         </image-cropper>

         <img *ngIf="iamgeType!='DLIMAGE'" [src]="selectedImage?.priview" [id]="iamgeType"
            style="width: 100%; transform: scalex(-1);" />
         <div class="text-center">
            <button class="btn btn-retake" (click)="onClickRetake()">Retake Photo</button>
            <button class="btn btn-next-move" (click)="onSelectImage()">Next</button>
         </div>
      </div>
   </div>


</p-dialog>