import { Observable } from 'rxjs';
import { InstantUcApiModels } from '../Api/i.api-response';

export abstract class IEncounterService {
    
    public abstract GetPatientEncounterDetails(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract SearchPharmacy(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract UpdatePreferredPharmacy(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract SearchMedication(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract MedicationStopReasons(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract AddMedication(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract SearchAllergies(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract AllergyReaction(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract AllergySeverity(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract GetMedication(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract DeleteMedication(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract AddAllergy(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract AddMedicationNote(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract GetPatientAllergy(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract GetPatientProblem(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract AddProblem(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract AddPatientSectionNote(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract DeleteProblem(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract DeletePharmacy(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract GetPharmacies(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract MedicationStopReasons_Local(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract AllergyReaction_Local(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract AllergySeverity_Local(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract Surgeries_Local(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract AddSurgery(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract GetPatientSurgeries(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract AddSocialHistory(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract GetSocialHistory(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract DeleteSurgery(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>;
    
}