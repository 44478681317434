
<!-- <router-outlet></router-outlet> -->

<div class="main-wrapper">
  <router-outlet (activate)="onActivate($event)" ></router-outlet> 
  <!-- <Instant-UC-header [DepartmentInfo]="department_details"></Instant-UC-header> -->

    <!-- <div class="content  justify-content-center">
      <div class="content-wrapper">
     
        <div class="container">
          <div class="row">
            <div class="col-xl-12 ml-auto mr-auto">
                <section class="contact-area section-padding-100">
                   
                            <div class="row no-gutters">
                                <div class="col-12">
                                    <div class="contact-form">
                                      <router-outlet></router-outlet> 
                                    </div>
                                </div>
                            </div>
                       
                </section>
              
            </div>
         
        </div>
      </div>
    </div>
  </div> -->

  <!-- <p-toast [style]="{marginTop: '2px'}" timeOut = 3 sticky="true"></p-toast> -->
  <p-toast [showTransformOptions]="'translateX(100%)'" sticky="true" position="bottom-right" sticky="true"></p-toast>

  <!--<div class="loader-image" *ngIf="globalService.loader">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

</div>-->
</div>


  
  
  
 
  
