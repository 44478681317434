import { environment } from "./../../../environments/environment";
import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "../http/http.service";
import { InstantUcApiModels } from "../interface/Api/i.api-response";

@Injectable({
  providedIn: "root",
})
export class TexonomyService {
  apiController: String = "api/Taxonomy";
  httpSerive: HttpService;
  constructor(injector: Injector) {
    this.httpSerive = injector.get(HttpService);
  }

  taxonomyList(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    const url = `${this.apiController}/TaxonomyList_Web`;
    return this.httpSerive.postHq(url, body);
  }

  AdmintexonomyList_Web(
    body
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    const url = `api/AdminTexonomy/AdminTexonomyList`;
    return this.httpSerive.postHq(url, body);
  }

  GetServiceList(body): Observable<any> {
    const url = `${this.apiController}/GetServiceList_Web`;
    return this.httpSerive.postHq(url, body);
  }

  GetSearchApi(body): Observable<any> {
    const url = `${environment.customDropdownApiUrl}/${body.query}/${body.Key}`;
    return this.httpSerive.getGoogle(url, body);
  }

  widgetPostMethod(body): Observable<any> {
    const url = `${body.folderName}/api/AdminWidget/GetDepartmentListForWidget_Type2`;
    return this.httpSerive.widgetPostMethod(url, body);
  }

  GetMilesList(body): Observable<any> {
    const url = `/api/Taxonomy/GetMilesList`;
    return this.httpSerive.postHq(url, body);
  }
}
