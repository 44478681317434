import { Observable } from 'rxjs';
import { InstantUcApiModels } from '../Api/i.api-response';

export abstract class IAccountService {
    public abstract SignUp(Obj: any): Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract GetUserInfoByActivationCode(Obj: any): Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract SetPassword(Obj: any): Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract UserLogin(Obj: any): Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract LogOut(Obj: any): Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract ForgotPassword(Obj: any): Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract ResetPassword(Obj: any): Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract GetTillowToken(Obj: any): Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract ParticipantList(Obj: any): Observable<InstantUcApiModels.InstantUcApiResponse>;
    public abstract roomDistroy(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>
    public abstract createRoom(Obj:any):Observable<InstantUcApiModels.InstantUcApiResponse>

}

