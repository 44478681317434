import { Input, Output, EventEmitter, Component } from '@angular/core';

@Component({
  selector: 'widget-loader',
  template: ``
})

export class NgInitComponent {

  @Input('isLast') isLast: boolean;

  @Output('ngInit') initEvent: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    if (this.isLast) {
      this.initEvent.emit()
    }
  }
}