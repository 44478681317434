import { BlogServices } from "../../../services/api-services/BlogService";
import { Component, OnInit } from '@angular/core';
declare var Instafeed:any;
@Component({
  selector: 'Instant-UC-pcfooter',
  templateUrl: './pcfooter.component.html',
  styleUrls: ['./pcfooter.component.scss']
})

export class PcfooterComponent implements OnInit {
  ServiceId: number|string = '';
  BlogList: any = [];
  BlogId: number|string = '';
  MasterList: Array<any> = [];
  currentYear:number = new Date().getFullYear();
  constructor(private blogService: BlogServices) { }

  ngOnInit() {
    this.GetBlogListById(0);
 
  }
  GetBlogListById(selectedPermission) {

    let data = {
      ServiceId: selectedPermission

    };
    this.blogService.GetBlogDetails(data).subscribe({
      next: (res) => {
        let page = 1;
        this.BlogList = res.Data.reverse().map(res => {
          res.isReadmore = false
          return res;
        });
       
       console.log(this.BlogList ,)



      },

      error: (error) => { },
    });
  }


}
