import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpBackend,
} from "@angular/common/http";

import { InstantUcApiModels } from "../interface/Api/i.api-response";
import { environment } from "@env";

import { catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import jwt_decode from 'jwt-decode';
import { Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
@Injectable({
  providedIn: "root",
})
export class HttpService {
  private httpWithoutInterceptor: HttpClient;
   envToken:any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private httpBackend: HttpBackend,
    @Inject(PLATFORM_ID) private platformID: Object
  ) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
    this.getPrecticeName();
  }

  getPrecticeName = () => {
    this.router.url;
    const [baseUrl, practiceName] = this.router.url.split("/");
    console.log(practiceName, "practice name https service");
    // localStorage.setItem("PracticeName",practiceName);
    return practiceName?practiceName.toLowerCase():'';
  };

  // post(url: string, body: any, loader = false, toast: boolean = true): Observable<InstantUcApiModels.InstantUcApiResponse> {
  //   if (loader) {
  //     // this.ls.show();
  //   }
  //   return this.http.post<InstantUcApiModels.InstantUcApiResponse>(environment.API_BASE_PATH + url, body)
  //     .pipe(catchError((err: HttpErrorResponse) =>{
  //       return this.handleError(err, toast)
  //     }));
  // }

  post(
    url: string,
    body: any,
    loader = false,
    toast: boolean = true
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    if (loader) {
      // this.ls.show();
    }
    body.apiKey = environment[this.getPrecticeName()]
      ? environment["API_KEY"]
      : environment.SelfOnboarding_Key;

    // body.apiKey = environment["API_KEY"];
      let token:any;
      let env;
      if (isPlatformBrowser(this.platformID)){
        token = localStorage.getItem('practiceSiteData');
         env = token?this.getDecodedAccessToken(token).API_BASE_PATH:this.getDecodedAccessToken(this.envToken);
        
        //  env = this.envToken?this.getDecodedAccessToken(this.envToken).API_BASE_PATH:this.getDecodedAccessToken(token).API_BASE_PATH;
       }else{
        env = this.envToken?this.getDecodedAccessToken(this.envToken).API_BASE_PATH:environment['d'];
      
     }
     console.log(env+ url,"api url")
    return this.http
      .post<InstantUcApiModels.InstantUcApiResponse>(
        env+ url,body)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.handleError(err, toast);
        })
      );
  }

  postHa(
    url: string,
    body: any,
    loader = false,
    toast: boolean = true
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    if (loader) {
      // this.ls.show();
    }
    body.apiKey = environment[this.getPrecticeName()]
      ? environment["HA_API_KEY"]
      : environment["HA_API_KEY"];
    return this.http
      .post<InstantUcApiModels.InstantUcApiResponse>(
        (environment[this.getPrecticeName()]
          ? environment[this.getPrecticeName()]
          : environment.HA_API_BASE_PATH) + url,
        body
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.handleError(err, toast);
        })
      );
  }

  postHq(
    url: string,
    body: any,
    loader = false,
    toast: boolean = true
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    console.log("hq post request===============")
    if (loader) {
      // this.ls.show();
    }
   
    body.apiKey = environment[this.getPrecticeName()]
      ? environment["API_KEY"]
      : environment.SelfOnboarding_Key;
    
    return this.http
      .post<InstantUcApiModels.InstantUcApiResponse>(
        (environment.API_BASE_PATH_Hq) + url,body)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.handleError(err, toast);
        })
      );
  }
  postHqWithoutApiKey( url: string,
    body: any,
    params?: any,
    loader = false,
    toast: boolean = true){
      let HqAuthToken:any;
      let UrgentCareLoginDetails:any;
    if (isPlatformBrowser(this.platformID)){
      HqAuthToken = JSON.parse(localStorage.getItem("HqAuthToken"));
      UrgentCareLoginDetails = JSON.parse(localStorage.getItem('UrgentCareLoginDetails'));
    }

    let header = new HttpHeaders().set(
      "Authorization" ,
      HqAuthToken).set(
      "UserName" ,UrgentCareLoginDetails?.Username) 
  

    return this.http
      .post<InstantUcApiModels.InstantUcApiResponse>(
       environment.API_BASE_PATH_Hq + url,
        body , 
        { headers: header , params}
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.handleError(err, toast);
        })
      );

  }

  getHq(
    url: string,
    params?: any,
    loader = false,
    toast = true
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    if (loader) {
      // this.ls.show();
    }

    let HqAuthToken:any;
    let UrgentCareLoginDetails:any;
    if (isPlatformBrowser(this.platformID)){
      HqAuthToken = JSON.parse(localStorage.getItem("HqAuthToken"));
      UrgentCareLoginDetails = JSON.parse(localStorage.getItem('UrgentCareLoginDetails'));
    }
    let header = new HttpHeaders().set(
      "Authorization" ,
      HqAuthToken).set(
      "UserName" ,UrgentCareLoginDetails?.Username) 

    return this.http
      .get<InstantUcApiModels.InstantUcApiResponse>(
        environment.API_BASE_PATH_Hq + url,
        { headers:header ,params }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }

  // directorypost(url: string, body: any, loader = false, toast: boolean = true): Observable<InstantUcApiModels.InstantUcApiResponse> {
  //   if (loader) {
  //     // this.ls.show();
  //   }
  //   return this.http.post<InstantUcApiModels.InstantUcApiResponse>(
  //     (environment[this.getPrecticeName()]?environment[this.getPrecticeName()]:environment.DRC_API_BASE_PATH) + url,body)
  //     .pipe(catchError((err: HttpErrorResponse) =>{
  //       return this.handleError(err, toast)
  //     }));
  // }

  get(
    url: string,
    params?: any,
    loader = false,
    toast = true
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    if (loader) {
      // this.ls.show();
    }
    return this.http
      .get<InstantUcApiModels.InstantUcApiResponse>(
        environment.API_BASE_PATH + url,
        { params }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }

  getLocal(url: string): Observable<any> {
    return this.http.get(url).pipe(
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      })
    );
  }

  getByAddressGioLocaltion(url: string): Observable<any> {
    return this.httpWithoutInterceptor
      .get<Response>(url)
      .pipe(catchError((err: HttpErrorResponse) => throwError(err)));
  }

  patch(
    url: string,
    body?: any,
    loader = false,
    toast?: boolean
  ): Observable<any> {
    if (loader) {
      // this.ls.show();
    }
    return this.http
      .patch(environment.API_BASE_PATH + url, body)
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }

  put(
    url: string,
    body?: any,
    loader = false,
    toast: boolean = true
  ): Observable<Response> {
    if (loader) {
      // this.ls.show();
    }
    return this.http
      .put<Response>(environment.API_BASE_PATH + url, body)
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }

  delete(url: string, query?: any, loader = false): Observable<Response> {
    if (loader) {
      // this.ls.show();
    }
    return this.http
      .delete<Response>(environment.API_BASE_PATH + url, { params: query })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.handleError(err, true);
        })
      );
  }

  deleteHq(
    url: string,
    body: any,
    query?: any,
    loader = false
  ): Observable<Response> {
    if (loader) {
      // this.ls.show();
    }

    let header = new HttpHeaders().set(
      "Authorization" ,
      "EV-OCT-EVENT-BIZBRO-2020LLY").set(
      "UserName" ,"kshitij@3bstaffing.com" ) 

    return this.http
      .delete<Response>(environment.API_BASE_PATH_Hq + url, {headers:header, params: query })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.handleError(err, true);
        })
      );
  }

  AthenaApipost(
    url: string,
    body: any,
    loader = false,
    toast: boolean = true
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    if (loader) {
      // this.ls.show();
    }
    //  const header = new  HttpHeaders().set('Content-Type', 'multipart/form-data');

    const header = new HttpHeaders({
      "Content-Type": "multipart/form-data",
      Authorization: "tokenneedtobechanged",
    });

    return this.http
      .post<InstantUcApiModels.InstantUcApiResponse>(
        environment.API_BASE_PATH + url,
        body,
        { headers: header }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }

  private handleError(err: HttpErrorResponse, toastView?: boolean) {
    if (toastView) {
      if (err.message) {
        // this.toast.error(err.message);
      }
    }
    return throwError(err);
  }

  getTillowToken(
    url: string,
    params?: any,
    loader = false,
    toast = true
  ): Observable<any> {
    let token:any;
    if (isPlatformBrowser(this.platformID)){
       token = localStorage.getItem('practiceSiteData');
       
      }
      let env = this.getDecodedAccessToken(token).socketUrl;
      url = `${env}${url}`;
   
    return this.http
      .get<any>(url, {params})
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }

  postForSocketApi(
    url: string,
    body: any,
    loader = false,
    toast: boolean = true
  ): Observable<any> {
    if (this.getPrecticeName().toLowerCase() == "medhealth")
      url = `${environment.medhealthSocketUrl}/${url}`;
    else url = `${environment.socketUrl}/${url}`;
    if (loader) {
      // this.ls.show();
    }
    return this.http.post<any>(url, body).pipe(
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err, toast);
      })
    );
  }
  getMaster(url: string): Observable<Response> {
    return this.http
      .get<Response>(url)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }

  getGoogle(
    url: string,
    params?: any,
    loader = false,
    toast = true
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    if (loader) {
      // this.ls.show();
    }
    let header = new HttpHeaders().set(
      "Authorization" ,
      "EV-OCT-EVENT-BIZBRO-2020LLY") 

    return this.http
      .get<InstantUcApiModels.InstantUcApiResponse>(url , {headers:header})
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }

  MultipartFormRequest(
    url: string,
    body: any,
    loader = false,
    toast: boolean = true
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    
    const header = new HttpHeaders({
      "Content-Type": "multipart/form-data",
    });
  
    

    return this.http
      .post<InstantUcApiModels.InstantUcApiResponse>(
        environment.API_BASE_PATH_Hq + url,
        body
      )
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }


  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
      
    } catch(Error) {
        console.log(Error)
      return null;
    }
  }

  getHqWithoutAuthorized(
    url: string,
    params?: any,
    loader = false,
    toast = true
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    
    let header = new HttpHeaders().set(
      "Authorization" ,
      "EV-OCT-EVENT-BIZBRO-2020LLY").set(
      "UserName" ,"kshitij@3bstaffing.com" ) 

    return this.http
      .get<InstantUcApiModels.InstantUcApiResponse>(
        environment.API_BASE_PATH_Hq + url,
        { headers:header ,params }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err, toast))
      );
  }


  widgetPostMethod(url,body){
    body.apiKey =  environment["API_KEY"];
    return this.http.post<InstantUcApiModels.InstantUcApiResponse>(
        environment.API_BASE_PATH+ url,body)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.handleError(err);
        })
      );
  }
}
