<div class="row">
  
    <div class="col-lg-12">
      <div class="ap-slot-right">
        <div class="row align-items-center appartment-types no-gutters more mt15">
          <div class="col-lg-12 Available-heading" *ngIf="!IsAptBlocked && isPageLoad">
            <label style="font-weight: bold">Available Appointments</label>
          </div>
  
  
          <div class="col-lg-12 col-12" *ngIf="!IsAptBlocked && isPageLoad">
            <div class="form-group select-vist-form row">
              <span class="col-lg-5">Appointment type :</span>
              <div class="col-lg-6">
                <select #mySelect class="form-control" [(ngModel)]="selectedApptType"
                  (change)="onChangeAppointType(mySelect.value)">
                  <!-- <option value="">--Select--</option> -->
                  <option *ngFor="let appoint of appointmentType"
                    [value]="appoint.AppointmentTypeId + ',' + appoint.LabelName">
                    {{ appoint.LabelName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
  
          <div class="col-12 col-lg-12">
            <div class="form-group">
              <div class="change-date-btn" [ngStyle]="{ display: finalList.length > 0 ? 'block' : 'none' }">
                <button name="prev" (click)="handlePrevious()"
                  class="btn-previous">
                  <i class="fa fa-chevron-left" aria-hidden="true"></i>
                </button>
                <button  (click)="handleNext()" class="btn-next">
                  <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
  
        <div class="items" style="display: block;">
          <div class="row">
            <div class="col-sm-12">
              <h4 class="book-date-head" style="border-left: 0">
  
                <label for="appointmentCalendar">
                  <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-calendar3"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"
                    />
                    <path
                      d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                    /></svg> -->
  
                  <!-- <input type="date" name="" id="appointmentCalendar" (change)="changeSelectedDate($event)">
              -->
                  <p-calendar [readonlyInput]="true" class="bookingCalander" [minDate]="currentDate"
                    [(ngModel)]="selectedDate1" id="appointmentCalendar" dateFormat="mm.dd.yyyy"
                    (onSelect)="changeSelectedDate($event)" [showOtherMonths]="false">
  
                    <ng-template pTemplate="date" let-date let-i="rowIndex">
                      <span style="position: relative;">
                        {{date.day}}
                      </span>
                      <span *ngIf="checkAvailableSlot(date)" style="padding: 2.5px;
                        display: block;
                        width: 5px;
                        margin: auto;
                        background: green;
                        border-radius: 50%;
                        top: 18px;
                        left: 1.5px;
                        text-align: center;"></span>
                    </ng-template>
                  </p-calendar>
                </label>
                <!-- <span>  {{ selectedDate | date:'fullDate' }} </span> -->
                <span *ngIf="selectedDate" style="position:relative;bottom:8px;"
                  class="w3-animate-top">{{getDate(selectedDate)}}</span>
                <small style="position: absolute;top: 29px;left: 48px;color: gray;"> Click to change date </small>
  
              </h4>
              <div class="time_button-Wrapper ">
                <button *ngFor="let slot of appointmentSlots[selectedDate]" class="w3-animate-top">
                  <span (click)="onselectTime($event,slot)">{{
                    slot?.starttime
                    }}</span>
                  <li>
                    <a class="confirm-time">{{ slot?.starttime }}</a>
                    <a class="confirm-btn" (click)="CheckAvailability(slot)">Confirm</a>
                  </li>
                </button>
                <a class="loadMore" (click)="showMoreOption()"
                  *ngIf="(appointmentSlots? appointmentSlots[selectedDate]?.length :false)" id="more-btn">more<i
                    class="fa fa-angle-down"></i></a>
                <a class="showLess" (click)="onClickLessBtn()"
                  *ngIf="appointmentSlots? appointmentSlots[selectedDate]?.length :false">less<i
                    class="fa fa-angle-up"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isPageLoad && !IsAptBlocked && !appointmentSlots[selectedDate]" class="no-search-box"
          style="color: red">
          <p *ngIf="nextSlotDate.length<1">No Slot Available!</p>
           <p *ngIf="nextSlotDate.length>0">Next slot available on 
              <span style="cursor: pointer;color: #007ad9;" (click)="selectedDate=nextSlotDate">{{nextSlotDate}}</span>
           </p> 
          Please call
          <a href="tel:{{ DepartmentInfo?.PhoneNumber }}">{{DepartmentInfo?.PhoneNumber}}</a>
          to book an appointment.
        </div>
  
        <div *ngIf="IsAptBlocked && isPageLoad" class="no-search-box">
          Please call
          <a href="tel:{{ DepartmentInfo?.PhoneNumber }}">{{
            DepartmentInfo?.PhoneNumber
            }}</a>
          to book an appointment.
        </div>
      </div>
    </div>
  </div>
    <!-- <p-dialog header="Select Appointment Type" class="app-form" [(visible)]="selectAppointmentPopupVisiblity" [draggable]="false"
      [resizable]="false" [style]="{ width: '33vw' }" [modal]="true">
      <div style="width: 98%">
        <div class="form-group select-vist-form row no-gutters align-items-center">
          <span class="col-lg-5">Appointment type :</span>
          <div class="col-lg-6">
            <select #mySelect class="form-control" [(ngModel)]="selectedApptType"
              (change)="onChangeAppointType(mySelect.value)">
              <option *ngFor="let appoint of appointmentType"
                [value]="appoint.AppointmentTypeId + ',' + appoint.LabelName">
                {{ appoint.LabelName }}
              </option>
            </select>
          </div>
        </div>
      </div>
  
      <p-footer>
        <button class="btn btn-primary" (click)="selectAppointmentPopupVisiblity = false">
          Proceed
        </button>
      </p-footer>
    </p-dialog>
   -->

  
  <InstantUC-loader [show]="loader"></InstantUC-loader>