import { Component, OnInit, ViewChild, Input, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { IAccountService } from '../../../services/interface/account/i.account-method';
import { TosterService } from '@toster';
import { Router } from '@angular/router';
import { GlobalService } from '@Globalservice';
import { isPlatformBrowser } from "@angular/common";
import { Inject, PLATFORM_ID } from "@angular/core";
@Component({
  selector: 'Instant-UC-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input('DepartmentInfo') departmentInfo: any;
  access_token:any;
  userLogin :any;
 DepartmentInfo:any;
  
  loginPage: boolean = false;
  isMedhealth: boolean = false;
  @ViewChild('logout') logoutOption: ElementRef;
  @Input('showLogin') showLogin: number = 1;
  constructor(
    private iAccountService: IAccountService,
    private router: Router,
    private tosterService: TosterService,
    private globalService: GlobalService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformID: Object
  ) {
   
    if (isPlatformBrowser(this.platformID)) {
      this.access_token = localStorage.getItem('bookingToken');
      this.userLogin = JSON.parse(localStorage.getItem('InstantUCPatientInfo'));
    }
    if (this.access_token) this.globalService.userLoginStatus.next(true);
  }

  ngOnInit() {
   
    this.globalService.loginBox$.subscribe(res => {
      this.loginPage = res;
    })
  }
  ngOnChanges(changes: SimpleChanges): void {
  }
  goToDepartmentWebSite() {
    if (this.departmentInfo.DepartmentWebsite && isPlatformBrowser(this.platformID)) {
      window.location.href = this.departmentInfo.DepartmentWebsite;
    }
    else {
      window.open();
    }

  }

  LogOut() {
    
    if (isPlatformBrowser(this.platformID)) {
    this.userLogin = JSON.parse(localStorage.getItem('InstantUCPatientInfo'));
    this.access_token = localStorage.getItem('bookingToken');
    }
    let data = {
      "ClientIPAddress": this.userLogin.ClientIPAddress,
      "LoggedInUserId": this.userLogin.UserId,
      "Username": this.userLogin.Username
    }
    this.iAccountService.LogOut(data).subscribe((res: any) => {

      localStorage.removeItem('InstantUCPatientInfo');
      localStorage.removeItem('bookingToken');
      this.access_token = null;
      this.tosterService.success(res.Message);
      this.changeDetectorRef.detectChanges();
      this.goToHome();
      this.globalService.userLoginStatus.next(false);
    },
      error => {
        if (error.status == 403) {
          this.tosterService.error(error.error.ErrorDetails);
        }
      })

  }

  public closeLoginPage = (res) => {
    
    if (isPlatformBrowser(this.platformID)) {
    this.userLogin = JSON.parse(localStorage.getItem('InstantUCPatientInfo'));
    this.access_token = localStorage.getItem('bookingToken');
    }
    this.loginPage = false;
    this.access_token = res.token;
    this.userLogin = res.patientDetails;
    this.changeDetectorRef.detectChanges();
    if (this.access_token) this.globalService.userLoginStatus.next(true);
    // this.goToHome();

  }

  goToHome() {
   
    if(isPlatformBrowser(this.platformID)){
     this.DepartmentInfo = JSON.parse(localStorage.getItem("InstantUCDpInfo"));
    }
    if (
      this.DepartmentInfo.DepartmentId &&
      this.DepartmentInfo.PracticeId &&
      this.DepartmentInfo.UniqueDepartmentCode
    ) {

      this.router.navigateByUrl(`${this.getPracticeName()}/${this.getName(this.DepartmentInfo.DepartmentName)}-${this.getName(this.DepartmentInfo.City)}-${this.getName(this.DepartmentInfo.State)}-${this.getName(this.DepartmentInfo.UniqueDepartmentCode)}`);
    } else {
      this.router.navigateByUrl(
        `p/${this.DepartmentInfo.PracticeId}/d/${this.DepartmentInfo.DepartmentId}/appointment`
      );
    }

  }

  getName(name) {
    let PName = name.replace(/ /gi, "-");
    return PName.toLowerCase();
  }

  getPracticeName() {
    const [baseUrl, practiceName] = this.router.url.split('/');
    return practiceName;
  }

}
