import { environment } from './../../../../environments/environment';
import { AfterViewInit, Component, OnInit, Inject } from '@angular/core';
import { isPlatformBrowser } from "@angular/common";
import { PLATFORM_ID } from "@angular/core";
declare var $:any
declare var gtag:any;

@Component({
  selector: 'Instant-UC-pcheader',
  templateUrl: './pcheader.component.html',
  styleUrls: ['./pcheader.component.scss']
})
export class PcheaderComponent implements OnInit,AfterViewInit {
  adminUrl:string= environment.adminUrl;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit() {
    console.log("this is header page====")
    this.loadscript();
  }
  ngAfterViewInit(): void {
      
  }
  loadscript(){
    if (isPlatformBrowser(this.platformId)) {
    $(".toggleclickbtn").click(function (e) {
      $("#top-menu-nav").toggleClass("show");
      $(".overlay").toggleClass("active");
    });
    $(".overlay").click(function (e) {
      $("#top-menu-nav").toggleClass("show");
      $(".overlay").removeClass("active");
    });
  }
  }

  GTMTractEvent(){
    gtag('event', 'CLICK', {
      'event_category': 'Find a Doctor',
      'event_label': `User click find doctor button`,
      'value': `Put a value here that is meaningful with respect to the click event`});
  }
}
