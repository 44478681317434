import { BlogDetailComponent } from './blog/blog-detail/blog-detail.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule,PreloadAllModules} from '@angular/router';
import {loginAuthgardComponent} from "./services/data-service/auth-gard-service";
import { VerificationComponent } from './patient/verification/verification.component';
import { DepartmentVisitComponent } from './department-visit/department-visit.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component'
import { Component } from '@angular/core';
import {PracticeDataResolver} from "./services/data-service/practice-resolver";


const routes: Routes = [

 

   {   
    path: '', loadChildren: ()=> import('./web-layout/web-layout.module').then(m=>m.WebLayoutModule)
   },
  {
    path:"p/:PracticeId/d/:department_id/appointment",
    loadChildren: () =>import('./appointment/appointment.module').then(m => m.AppointmentModule),
  },
  {
    path:'instant-urgent-care--ca-te53483',
    redirectTo:'instant-urgent-care-telemedicine-ca-te53483',
    pathMatch:'full'
  },
  {
     path:"d",
     loadChildren: () =>import('./pcwebpage/pcwebpage.module').then(m => m.PcwebpageModule),
  },
  // { 
  //    path:":name",
  //   loadChildren: () =>import('./appointment/appointment.module').then(m => m.AppointmentModule),
  // },
  
  {
    path: ':practiceN/patient',
    loadChildren: ()=> import('./patient/patient.module').then(m=>m.PatientModule)
   },

   {
    path: 'widget', component: DepartmentVisitComponent
   },
   {
    path: 'verify', component: EmailVerificationComponent
   },
   
  {
    path:":practiceN/apt",
    
    loadChildren: () =>import('./landing-page/landing-page.module').then(m => m.LandingPageModule),
  },
  {
    path:"patient/User",
    loadChildren: () =>import('./authrized-page/authrized-page.module').then(m => m.AuthrizedPageModule),
  },

  {
    path: 'biz/:name', pathMatch:'full', loadChildren: ()=> import ('./web-layout/web-page/searchresultdeatils/searchresultdetails.module').then(m=> m.BusinessLocationModule)
  },
  
  {   // this one is for booking page
    path:":practiceN/:name",
    // resolve: { data: PracticeDataResolver}, 
    loadChildren: () =>import('./appointment/appointment.module').then(m => m.AppointmentModule),
  },
  
 

 
  {
    path:'**',
    redirectTo:""
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy', initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
