
<footer class="web-footer">
    <div class="container">
        <div class="row">
            <div class="col-sm-3">
              <div class="footer-logo">
                <img src="assets/images/logo.png">
                 <p>Practice Crown stands out as the optimal solution for complete Practice Management services, facilitating the seamless launch of your practice in the online domain With a single click .</p>
              </div>
              <ul class="social">
                <li><a target="_blank" href=" https://www.linkedin.com/company/practicecrown/">
                   
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 80 80">
            <g id="Group_13110" data-name="Group 13110" transform="translate(-10 -10)">
              <path id="Path_43168" data-name="Path 43168" d="M90,90V60.7c0-14.4-3.1-25.4-19.9-25.4-8.1,0-13.5,4.4-15.7,8.6h-.2V36.6H38.3V90H54.9V63.5c0-7,1.3-13.7,9.9-13.7,8.5,0,8.6,7.9,8.6,14.1v26H90ZM11.3,36.6H27.9V90H11.3ZM19.6,10a9.65,9.65,0,1,0,9.6,9.6A9.6,9.6,0,0,0,19.6,10Z" fill="#fff"/>
            </g>
          </svg>

                  
                </a></li>
                  <li><a target="_blank" href="https://twitter.com/practicecrown">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="15" height="15" x="0" y="0" viewBox="0 0 1226.37 1226.37" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M727.348 519.284 1174.075 0h-105.86L680.322 450.887 370.513 0H13.185l468.492 681.821L13.185 1226.37h105.866l409.625-476.152 327.181 476.152h357.328L727.322 519.284zM582.35 687.828l-47.468-67.894-377.686-540.24H319.8l304.797 435.991 47.468 67.894 396.2 566.721H905.661L582.35 687.854z" opacity="1" data-original="#000000" class=""></path></g></svg>  
                  
                  </a></li>
                  <li>
                    <a  target="_blank"href="https://instagram.com/practicecrown?igshid=YmMyMTA2M2Y">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                          </svg>
                    </a>
                  </li>
                  <li>
                    <a  target="_blank"href="https://www.facebook.com/profile.php?id=100084806512239">     
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 43.1 79.9">
                  <g id="Group_13111" data-name="Group 13111" transform="translate(-28.4 -10.1)">
                    <path id="Path_43169" data-name="Path 43169" d="M40.4,55.2H30.5c-1.6,0-2.1-.6-2.1-2.1V41c0-1.6.6-2.1,2.1-2.1h9.9V30.1a22.213,22.213,0,0,1,2.7-11.3A16.592,16.592,0,0,1,52,11.4a22.249,22.249,0,0,1,7.7-1.3h9.8c1.4,0,2,.6,2,2V23.5c0,1.4-.6,2-2,2-2.7,0-5.4,0-8.1.1-2.7,0-4.1,1.3-4.1,4.1-.1,3,0,5.9,0,9H68.9c1.6,0,2.2.6,2.2,2.2V53c0,1.6-.5,2.1-2.2,2.1H57.3V87.7c0,1.7-.5,2.3-2.3,2.3H42.5c-1.5,0-2.1-.6-2.1-2.1Z" fill="#fff"/>
                  </g>
                </svg>

                    </a>
                  </li>
              </ul>
            </div>
            <div class="col-sm-9">
       
              <div class="row m-0">
                <div class="col-sm-6 pl-0">
                  <h4>Services</h4>
                  <div class="row m-0">
                    <div class="col-sm-6 p-0">
                      <ul>
                        <li>
                            <a  routerLinkActive="active" routerLink="/appointment-reminders">Appointment Reminders</a>
                        </li>
                        <li>
                            <a routerLinkActive="active" routerLink="/digital-check-in">Digital Check-In</a>
                        </li>
                        <li>
                            <a routerLinkActive="active" routerLink="/digital-marketing">Digital Marketing</a>
                        </li>
                        <li>
                            <a routerLinkActive="active" routerLink="/inventory-management">Inventory Management</a>
                        </li>
                        <li>
                            <a routerLinkActive="active" routerLink="/marketing-campaigns">Marketing Campaigns</a>
                        </li>
                        <li>
                            <a routerLinkActive="active" routerLink="/online-appointment-booking">Online Appointment Booking</a>
                        </li>
                      
                     
                          
                   
                    </ul>
                    </div>
                    <div class="col-sm-6 p-0">
                      <ul>
                       
                       
                        <li>
                            <a routerLinkActive="active" routerLink="/reputation-management">Reputation Management</a>
                        </li>
                       
                        <li>
                            <a routerLinkActive="active" routerLink="/telemedicine">Telemedicine</a>
                        </li>
                        <li>
                            <a routerLinkActive="active" routerLink="/search-engine-optimization">SEO</a>
                        </li>
                     
                      <li>
                          <a routerLinkActive="active" routerLink="/text-messaging">Text Messaging</a>
                      </li>
                      <li>
                        <a routerLinkActive="active" routerLink="/website-development">Website Development</a>
                    </li>
                       
                       
                      </ul>
                    </div>
                  </div>
                
                  
                </div>
                
                  <div class="col-sm-3">
                    <h4>USEFUL LINKS</h4>
                    <ul>
                      <li><a routerLink="/Blogs">Blogs</a></li>
                     
                      <li>
                        <a class="sitemap1" routerLink="/about">About Us</a>
                    </li>
                      <li>
                          <a class="sitemap" routerLink="/sitemap" >Sitemap </a>
                      </li>
                      
                      <li>
                          <a class="sitemap1" routerLink="/directoryPages" >Directory pages</a>
                      </li>
                     
                          
                   
                    </ul>
                </div>
                  <div class="col-sm-3">
                    <h4>Latest Blog</h4>

                    
                    <ul class="lates-news">
                      <li  *ngFor="let items of BlogList.slice(0, 2)">
                       
                        <div class="thumb">
                            
                            <img src="{{items?.Bannerimage}}">
                        </div>
                        <div class="lates-content">

                            <h3>{{items?.Title.slice(0, 20)}} <span *ngIf="items?.Title.length>20">....</span> </h3>
                            <h5>{{items?.CreatedDate}}</h5>
                       
                        </div>
                  
                      </li>
                     
                    </ul>
                </div>
              </div>
            </div>
        </div>
    </div>
    <div class="footer-bootam">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <p> ©{{currentYear}}   <a target="_blank" href="https://practicecrown.com/">Practicecrown All Rights Reserved</a></p>
                </div>
               
            </div>
        </div>
        </div>
</footer>

