import { Component, OnInit, ViewChild, Renderer2, ElementRef, Input, Output, EventEmitter,} from '@angular/core';
declare var $: any;
declare var userStream: any;
import { ImageCroppedEvent } from "ngx-image-cropper";
@Component({
  selector: 'Instant-UC-capture-image',
  templateUrl: './capture-image.component.html',
  styleUrls: ['./capture-image.component.scss']
})
export class CaptureImageComponent implements OnInit {
  captureModal: boolean = false;
  @Input("imageType") iamgeType;
  @Output() OnCaptuteImage: EventEmitter<any> = new EventEmitter();
  videoWidth = 0;
  videoHeight = 0;
  @ViewChild('video', { static: true }) videoElement: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  @Input('Sequence') sequence:any = 0;

  @ViewChild('croppedCanvas') croppedCanvas: ElementRef;
  @ViewChild('cropOverlay') cropOverlay: ElementRef;

  constraints:any = {
    video: {
      facingMode: "environment",
      width: { ideal: 420}, //4096
      height: { ideal: 400  } //2160
    }
  };
  isCapture: boolean;
  selectedImage = {
    image: null,
    imageType: null,
    priview: null
  };
  stream: any;
  imageChangedEvent: any = "";
  constructor(private renderer: Renderer2) { }

  ngOnInit() {
  }

  


  startCamera() {
    if(window.screen.width<420){
      this.constraints.video.width = 360;
      this.constraints.video.height = 360 ;
       
    } 
    else if(window.screen.width>420 && window.screen.width<670){
      this.constraints.video.width = 620;
      this.constraints.video.height = 480 ;
    }
    else{
      this.constraints.video.width = 1920;
       this.constraints.video.height = 1080 ;
    }


    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      navigator.mediaDevices.getUserMedia(this.constraints)
        .then(res => {
          $(`.camera-container${this.sequence}`).show();

          this.attachVideo(res)
        }).catch(this.handleError);
    } else {
      alert('Sorry, camera not available.');
    }
  }

  handleError(error) {
    this.isCapture = false;
  }

  attachVideo(stream) {

    this.stream = stream;
    this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
    this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
      this.isCapture = true;
      this.videoHeight = this.videoElement.nativeElement.videoHeight;
      this.videoWidth = this.videoElement.nativeElement.videoWidth;
      this.drawCropOverlay();
    })
  }

  drawCropOverlay(): void {
    const overlay = this.cropOverlay.nativeElement;
    // overlay.style.background = 'rgba(0, 0, 0, 0.5)'; // Semi-transparent black background
    overlay.style.border = '2px dotted #fff'; // White border
    // Add any other styles or positioning as needed for your cropping overlay
  }

  capture() {
    this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
    this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
    this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);

    let base64image = this.canvas.nativeElement.toDataURL("image/jpg");
    let blob = this.DataURIToBlob(base64image)
    let file = new File([blob], "Dl-image.jpg", { type: "image/jpg", lastModified: Date.now() });
    this.captureModal = true;
    $(`.camera-container${this.sequence} .capture-images`).show();
    //  $(`#${this.iamgeType}`).attr('src', base64image);
    this.selectedImage = {
      image: file,
      imageType: this.iamgeType,
      priview: base64image
    }
  }

  captureCroppedImage(): void {
    const video = this.videoElement.nativeElement;
    const overlay = this.cropOverlay.nativeElement;
    const croppedCanvas = this.croppedCanvas.nativeElement;
    const ctx = croppedCanvas.getContext('2d');
  
    // Calculate the position and size of the cropping overlay relative to the video element
    const videoRect = video.getBoundingClientRect();
    const overlayRect = overlay.getBoundingClientRect();
    const x = overlayRect.left - videoRect.left;
    const y = overlayRect.top - videoRect.top;
    const width = overlayRect.width;
    const height = overlayRect.height;
  
    // Set the size of the cropped canvas
    croppedCanvas.width = width;
    croppedCanvas.height = height;
  
    // Draw the portion of the video inside the cropping overlay onto the cropped canvas
    ctx.drawImage(video, x, y, width, height, 0, 0, width, height);
  
    // Extract the image data from the cropped canvas
    // const imageData = croppedCanvas.toDataURL('image/png');
    
    let base64image = this.croppedCanvas.nativeElement.toDataURL("image/jpg");
      let blob = this.DataURIToBlob(base64image)
      let file = new File([blob], "Dl-image.jpg", { type: "image/jpg", lastModified: Date.now() });
      $(`.camera-container${this.sequence} .capture-images`).show();
      //  $(`#${this.iamgeType}`).attr('src', base64image);
      this.selectedImage = {
        image: file,
        imageType: this.iamgeType,
        priview: base64image
      }
      this.captureModal = true;
  }

  onClickRetake() {
    this.captureModal = false;
    this.selectedImage.priview = null;
  }

  onSelectImage() {
    this.captureModal = false;
    this.OnCaptuteImage.emit(this.selectedImage);
    this.offCamera();
  }

  offCamera() {
   

    $(`.camera-container${this.sequence}`).hide();
    $(`.camera-container${this.sequence} .capture-images`).hide();
    const videoTrack = this.stream.getTracks();
    // var videoTrack = this.stream.getVideoTracks();
    videoTrack.forEach(element => {
      element.stop()
      // this.stream.removeTrack(element);
    });

    this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', null);
    this.stream =null;
    this.isCapture = false;
    // this.detectChanges.detectChanges()
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

  public onImageCropped = (event: ImageCroppedEvent) => {
    console.log(event.base64)
    let blob = this.DataURIToBlob(event.base64)
    let file = new File([blob], "Dl-image.jpg", { type: "image/jpg", lastModified: Date.now() });
    this.selectedImage = {
      image: file,
      imageType: this.iamgeType,
      priview: event.base64
    }
  }

  public cropperReady = (event) => {

  }


}
