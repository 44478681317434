import {
  Component,
  ViewChild,
  OnInit,
  EventEmitter,
  Output,
  ElementRef,
  Input,
} from "@angular/core";
import { CaptureImageComponent } from "../capture-image/capture-image.component";
import mergeImages from "merge-images";
import { ImageCroppedEvent } from "ngx-image-cropper";
declare var $: any;
@Component({
  selector: "Instant-UC-merge-image",
  templateUrl: "./merge-image.component.html",
  styleUrls: ["./merge-image.component.scss"],
})
export class MergeImageComponent implements OnInit {
  @ViewChild(CaptureImageComponent, { static: true })
  captureImageComponent: CaptureImageComponent;
  uploadImageModal: boolean = false;
  imageChangedEvent: any = "";
  croppedImage: any = [];
  imageType: any = "";
  DlFrontImage = "";
  DlBackImage = "";
  selectedBackImage: any;
  finalImage: any;
  @Input("ModelType") pageHeading;
  @Input("source") source;
  @ViewChild("frontimage") fronImage: ElementRef;
  @ViewChild("backimage") backImage: ElementRef;
  @Output() upload: EventEmitter<any> = new EventEmitter();
  @Output() OnCancle: EventEmitter<any> = new EventEmitter();
  @Output() OnClose: EventEmitter<any> = new EventEmitter();
  newImage: File;
  isfront: boolean = true;
  isBack: boolean = false;
  show: number = 0;

  showUploadSpinner: any = 1;
  showDefaultImage: any = 1;
  constructor() {}

  ngOnInit() {
    console.log(this.showDefaultImage);
  }

  frontImageChangeEvent(event: any, Type): void {
    this.imageType = Type;
    console.log(event);
    // by priyanshi
    this.imageType = event.target.files[0];
     console.log(this.imageType,"SelectedImage");
    let ext = this.imageType.name.split(".").pop();
        ext = ext.toLowerCase();
        console.log("image type",ext);
        ext == "png" || ext == "jpeg";
    console.log(ext, "SelectedImage");
    if (ext == "jpg" || ext == "png" || ext == "jpeg") {
      //by priyanshi
      if (event.target.files.length) {
        this.showDefaultImage = 0;
        this.imageChangedEvent = event;
        this.showDefaultImage = 2;
      }
         //by priyanshi
    } else {
      alert("Invalid file type,You can upload only image type(jpg,png,jpeg).");
    }
       //by priyanshi
  }

  backimageChangeEvent(event: any, Type): void {
    this.imageType = Type;
       //by priyanshi 
     this.imageType = event.target.files[0];
    console.log(this.imageType, "SelectedImage");
    let ext = this.imageType.name.split(".").pop();
    ext = ext.toLowerCase();
    ext == "png" || ext == "jpeg";
    console.log(ext, "SelectedImage");
    if (ext == "jpg" || ext == "png" || ext == "jpeg") {
         //by priyanshi
      if (event.target.files.length) {
        this.selectedBackImage = event;
        this.showDefaultImage = 0;
        this.OnCancle.emit(this.show);
        this.show = 1;
      }
         //by priyanshi
    } else {
      alert("Invalid file type,You can upload only image type(jpg,png,jpeg).");
    }
       //by priyanshi
  }

  backImageCropped(event: ImageCroppedEvent) {
    this.DlBackImage = event.base64;
    // if(this.DlFrontImage){
    //   this.mergeImage();
    // }
  }

  frontImageCropped(event: ImageCroppedEvent) {
    this.DlFrontImage = event.base64;
    // if(this.DlBackImage){
    //   this.mergeImage();
    // }
  }

  imageLoaded(event) {}

  cropperReady(event) {}

  resetForm() {
    this.finalImage = null;
    this.selectedBackImage = null;
    this.imageChangedEvent = null;
    this.fronImage.nativeElement.value = "";
    this.backImage.nativeElement.value = "";
    this.isBack = false;
    this.isfront = true;
    this.DlBackImage = null;
    this.DlFrontImage = null;
    this.showDefaultImage = 1;
    this.newImage = null;
    this.OnCancle.emit(this.show);
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  mergeImage() {
    mergeImages(
      [
        { src: this.DlFrontImage, x: 10, y: 10 },
        { src: this.DlBackImage, x: 10, y: 430 },
      ],
      {
        height: 800,
        width: 420,
      }
    ).then((b64) => {
      this.finalImage = b64;
      let blob = this.DataURIToBlob(this.finalImage);

      this.newImage = new File([blob], "Dl-image.jpg", {
        type: "image/jpg",
        lastModified: Date.now(),
      });
      this.upload.emit(this.newImage);
      
      //  this.UploadDL() 
    });
  }

  onConfirmForntImage() {
    this.isfront = false;
    this.isBack = true;
  }

  uploadImage() {
    this.showUploadSpinner = 2;
    this.mergeImage();
    if(this.source != "aio"){
    this.showUploadSpinner = 1;
    }
    // this.upload.emit(this.newImage)
  }

  OnCaptuteImage(event) {
    if(this.showDefaultImage == 1){
      this.showDefaultImage = 2
    }
    else{
      this.showDefaultImage=3;
    }
    
    console.log(event);
    if (event.imageType == "InsuranceFront") {
      console.log(event, "inside");
      this.imageChangedEvent = event.priview;
    } else if (event.imageType == "InsuranceBack") {
      this.selectedBackImage = event.priview;
    } else {
    }
  }
}
