import { TosterService } from "@toster";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { environment } from "./../../../../environments/environment";
import { TexonomyService } from "src/app/services/api-services/texonomy.service";
import { debounceTime, subscribeOn } from "rxjs/operators";
import { Input } from "@angular/core";
import { withoutValidation } from "ngx-mask";
import { BehaviorSubject } from "rxjs";
declare var $: any;
import { AbstractControl } from "@angular/forms";
import { PLATFORM_ID, Inject } from "@angular/core";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";

@Component({
  selector: "Instant-UC-custom-search",
  templateUrl: "./custom-search.component.html",
  styleUrls: ["./custom-search.component.scss"],
})
export class CustomSearchComponent implements OnInit {
  searchLocation = new FormControl("");
  placeList: any;
  selectedLocation: {
    location: string;
    latitude: string;
    longitude: string;
  };
  userCurrentLocation: any;
  autoCompleteId: any;
  searchKeyword: any;
  @Input("Location") location: any;
  @Output("LocationKeyword") locationKeyword: EventEmitter<string> =
    new EventEmitter();
  loader: boolean = false;
  processChange: any;
  @Input("isInvalid") isInvalidLocation: boolean = false;
  isInvalidForm: boolean = false;
  @Input("pagetype") autoCompleteId1: any;
  prevValue: string = "";
  apiHitCount: number = 0;
  resultsData: any;
  currentlocation: any;

  constructor(
    private texonomyService: TexonomyService,
    private router: Router,
    private ac: ActivatedRoute,
    private tosterService: TosterService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngOnInit(): void {
    console.log(this.location);
    if (this.autoCompleteId1 == "searchWebPage" && isPlatformBrowser(this.platformId)) {
      sessionStorage.removeItem("selectedLocation");
    }

    console.log(this.currentlocation, this.location, this.locationKeyword);
    var locationregex = "^[a-zA-Z0-9,_ ]*$";
    this.searchLocation.valueChanges.subscribe((res) => {
      if (res.length > 0 && isPlatformBrowser(this.platformId)) {
        // if (this.autoCompleteId1 == "searchWebPage") {
         
          sessionStorage.removeItem("emptySearch");
          
        // }
        // if(res.match(locationregex)){
        this.processChange = this.debounce(() => this.searchPlace(res), 400);

        // }

        // else{
        this.isInvalidLocation = true;
        // }
      } else {
        if (isPlatformBrowser(this.platformId)) {
          sessionStorage.setItem("emptySearch", "empty");

          this.processChange = this.debounce(() => this.searchPlace(""), 400);
          this.placeList = [];
        }
      }
    });

    console.log(this.autoCompleteId1);
  }

  timeout;
  debounce = (func, wait) => {
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(this.timeout);
        func(...args);
      };
      clearTimeout(this.timeout);
      this.timeout = setTimeout(later, 400);
    };
  };

  searchPlace(event: any) {
    this.apiHitCount += 1;
    let count = this.apiHitCount;
    event = event.trim();
    if (event && event != this.prevValue) {
      this.loader = true;
      let data = {
        query: event.replaceAll("+", ""),
        Key: environment.googleMapKey,
      };
      if (event) {
        this.texonomyService.GetSearchApi(data).subscribe({
          next: (res: any) => {
            this.resultsData = res;
            this.placeList = res.results;
            this.isInvalidForm = false;
            this.loader = false;
          },
          error: (err) => {
            this.placeList = [];
            this.loader = false;
          },
        });
      } else {
        this.loader = false;
        this.isInvalidLocation = true;
      }
      this.prevValue = event;
    } else if (!event) {
      this.placeList = [];
      this.isInvalidForm = true;
    }
  }

  selectLocation(event) {
    let place = event;
    this.location = place.formatted_address.split(",").splice(0, 2).join(",");
    console.log(this.location, "locationSplir===");

    this.locationKeyword.emit(this.location);
    this.placeList = [];

    this.selectedLocation = {
      latitude: parseFloat(place.geometry.location.lat).toFixed(6) ?? "",
      longitude: parseFloat(place.geometry.location.lng).toFixed(6) ?? "",
      location: place.formatted_address.split(",").splice(0, 2).join(",") ?? "",
    };
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem(
        "selectedLocation",
        `${JSON.stringify(this.selectedLocation)}`
      );
    }
  }
}
