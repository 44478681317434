import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'Instant-UC-pcwebpage',
  templateUrl: './pcwebpage.component.html',
  styleUrls: ['./pcwebpage.component.scss']
})
export class PcwebpageComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private doc:any) {
    let link: HTMLLinkElement = doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    doc.head.appendChild(link);
    link.setAttribute('href', doc.URL); 
   }

  ngOnInit() {
  }

}
