<p-dialog
  [header]="pageHeading"
  [(visible)]="uploadImageModal"
  [modal]="true"
  [responsive]="true"
  [style]="{ width: '350px', minWidth: '200px' }"
  [maximizable]="false"
  class="full-screen-modal"
  [minY]="70"
  (onHide)="resetForm()"
  (onShow)="show = 0"
  position="top"
  [maximizable]="false"
  [draggable]="false"
  [baseZIndex]="10000"
>
  <div class="container">
    <div class="hide" [ngClass]="{ show: isfront }">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-6  col-6" *ngIf="this.showDefaultImage == 1">
          <img src="assets/img/front.jpg" class="up-ins-img" />
        </div>

        <div class="col-lg-12" *ngIf="!DlFrontImage">
          <p class="col-red pic-con">Upload front side of the insurance card</p>
        </div>
        <div class="col-md-12 col-12">
          <div class="crop-div text-center"   [ngClass]="{ hightfixed: DlFrontImage }">
            <image-cropper 
              [imageBase64]="imageChangedEvent"
              [imageChangedEvent]="imageChangedEvent"      
              [maintainAspectRatio]="false"
              [aspectRatio]="2 / 1"
              [disabled]="false"
              style="width: 100%"
              format="png"
              [resizeToWidth]="400"
              [resizeToHeight]="400"
              (imageCropped)="frontImageCropped($event)"
              (imageLoaded)="imageLoaded($event)"
              (cropperReady)="cropperReady($event)"
            >
            </image-cropper>
            
            <div *ngIf="DlFrontImage" class="tooltip">
              Tooltip
              <span class="tooltiptext"
                >Please crop the correct section and Save</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-5 col-12 text-center"  *ngIf="DlFrontImage">
          <label
          (click)="onConfirmForntImage()"
          class="upload-file-btn">Save</label>
        </div>

        <div class="col-md-5 col-12 text-center">
         
          <label for="front-image" class="button-round">
            <span *ngIf="DlFrontImage"> Retake </span>   <span *ngIf="!DlFrontImage"> Upload  Front Image </span> 
            <input
              type="file"
              #frontimage
              id="front-image"
             
              (change)="frontImageChangeEvent($event, 'front')"
              style="display: none"
            />
          </label>
          
        </div>
        <div class="col-md-5 col-12  hide-on-phone">
          <Instant-UC-capture-image
            (OnCaptuteImage)="OnCaptuteImage($event)"
            imageType="InsuranceFront"
          >
          </Instant-UC-capture-image>
          <!-- <div  class="camera-icon"><i  aria-hidden="true" class="fa fa-camera" title="Open Camera"></i></div> -->
        </div>
       


       
      </div>
      
    </div>

    <div class="hide" [ngClass]="{ show: isBack }">
      <div class="row justify-content-center align-items-center" *ngIf="showUploadSpinner == 1">
        <div class="col-md-6 col-6" *ngIf="this.showDefaultImage == 2">
          <img
            src="assets/img/back.jpg"
            style="width: 100%"
            class="up-ins-img"
          />
        </div>

        <div class="col-lg-12" *ngIf="!DlBackImage">
          <p class="col-red pic-con">Upload back side of the insurance card</p>
        </div>
        <div class="col-md-12 col-12 text-center" *ngIf="showUploadSpinner == 1">
          <div class="crop-div text-center"   [ngClass]="{ hightfixed: DlBackImage }">
          <image-cropper
            [imageBase64]="selectedBackImage"
            [imageChangedEvent]="selectedBackImage"
            [maintainAspectRatio]="false"
            [aspectRatio]="2 / 1"
            [disabled]="false"
            format="png"
            style="width: 100%"
            [resizeToWidth]="400"
            [resizeToHeight]="400"
            (imageCropped)="backImageCropped($event)"
            (imageLoaded)="imageLoaded($event)"
            (cropperReady)="cropperReady($event)"
          >
          </image-cropper>
        </div> </div>

        <ng-container  *ngIf="showUploadSpinner == 1">
          <div class="col-md-5  col-12 text-center"  *ngIf="DlBackImage">
            <label
              (click)="uploadImage()"
              class="upload-file-btn"
              >Save</label
            >
          </div>
        </ng-container>

        <div class="col-md-5 col-12 text-center">
          <label for="back-image" class="button-round">
           
         <span *ngIf="DlBackImage"> Retake</span>    <span *ngIf="!DlBackImage"> Upload Back Image</span> 
            <input
              #backimage
              type="file"
              id="back-image"
              
              (change)="backimageChangeEvent($event, 'back')"
              style="display: none"
            />
          </label>
        </div>
        <div class="col-md-5 col-12  hide-on-phone">
          <Instant-UC-capture-image
            (OnCaptuteImage)="OnCaptuteImage($event)"
            imageType="InsuranceBack"
          >
          </Instant-UC-capture-image>
          <!-- <div  class="camera-icon"><i  aria-hidden="true" class="fa fa-camera" title="Open Camera"></i></div> -->
        </div>
       
        
       
        
      </div>
      
       
        <div class="row justify-content-center">
          <div class="col-md-6 col-6">
            <!-- <div class="booking-loder"> 
            <div class="preloader">
                <img src="assets/img/tenor.gif"> 
             </div>

           </div>   -->
            <div class="booking-loder" *ngIf="showUploadSpinner != 1">
              <div class="preloader">
                <img src="assets/img/tenor.gif" />
              </div>
            </div>
          </div>
        </div>
     
    </div>
  </div>
</p-dialog>
