// import { BusinessOwnerGuardComponent } from './services/data-service/business-owner-guard.service';
// import { BlogDetailComponent } from './blog/blog-detail/blog-detail.component';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import {loginAuthgardComponent} from "./services/data-service/auth-gard-service";
import {HttpConfigInterceptor} from "./services/http/http-interceptor";
import {ShareModule} from './share/share.module'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {AccordionModule} from 'primeng/accordion';
// import {RouterModule} from '@angular/router'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { IAppointmentMTService } from './services/interface/appointment/i.appointment-method';
import { AppointmentService } from './services/api-services/Appointment';
import { DepartmentVisitComponent } from './department-visit/department-visit.component';
//import {StorageServiceModule} from 'angular-webstorage-service';
// import { SignaturePadModule} from 'angular2-signaturepad';
import { EmailVerificationComponent } from './email-verification/email-verification.component'

import {IAccountService} from './services/interface/account/i.account-method';
import {AccountService} from './services/api-services/accout';
import { PcwebpageComponent } from './pcwebpage/pcwebpage.component';







@NgModule({
  declarations: [
    AppComponent,
    DepartmentVisitComponent,
    EmailVerificationComponent,
    PcwebpageComponent,

      // BlogDetailComponent
  
    
   
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    
    // RouterModule,
    HttpClientModule,
    ShareModule,
    ToastModule,
    ProgressSpinnerModule,
    // SignaturePadModule,
    FormsModule,
     ReactiveFormsModule,
     ShareModule,

    //  ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    loginAuthgardComponent,
    { provide: IAppointmentMTService, useClass: AppointmentService },
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    {
      provide:IAccountService,useClass:AccountService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
