import { Injectable,Inject } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
@Injectable({"providedIn":"root"})

export class GlobalService {

  constructor(@Inject(DOCUMENT) private doc:any) {

  }

  loader:boolean = false;  
  loginSubscribe$ = new Subject<any>();
  loginBox$ = new Subject<any>();
  userLoginStatus = new BehaviorSubject(false);
  seoDetails:any = {};
  
    
    //==== mm/dd/yyyy 
    dateFormater(date){
      let newDate = new Date(date);
      let d= newDate.getDate();
      let M = newDate.getMonth()+1;
      let y = newDate.getFullYear();
      return `${M}/${d}/${y}`
      
    }

    _calculateAge(DOB) { // birthday is a date
      let birthday = new Date(DOB);
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  pageDescriptio(content){
    var m = this.doc.createElement('meta'); 
    m.name = 'description'; 
    m.content = content; 
    this.doc.head.appendChild(m);
  }

  addclassBody(){
    let html = this.doc.getElementsByName('html');
    let body = this.doc.getElementsByName('body');

  }

  

}