import{environment} from '@env'
import { Component, OnInit,Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';
declare var $:any;
import { isPlatformBrowser } from "@angular/common";
import { Inject, PLATFORM_ID } from "@angular/core";

@Component({
  selector: 'Instant-UC-verify-header',
  templateUrl: './verify-header.component.html',
  styleUrls: ['./verify-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerifyHeaderComponent implements OnInit,OnChanges {

  @Input('DepartmentDetails') department_details: any;

  depId:any=environment.DEP_ID;
  practiceId:any=environment.PRACTICE_ID;
  constructor(
    @Inject(PLATFORM_ID) private platformID: Object
  ) { 
   
  }

  ngOnInit() {
 
    if(isPlatformBrowser(this.platformID)){
    this.department_details = JSON.parse(localStorage.getItem('InstantUCApInfo'));
    }
    // alert(this.department_details);
    console.log(this.depId,this.practiceId,"hey there");
  }

  ngOnChanges() {
    // console.log(department_details);
    // console.log(this.department_details.DepartmentId,this.department_details.PracticeId);
  }

}
