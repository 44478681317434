
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA, Input} from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './component/header/header.component';
import { CommondivComponent } from './component/commondiv/commondiv.component';
import {CarouselModule} from 'primeng/carousel';
import { CaptureImageComponent } from './component/capture-image/capture-image.component';
import { CountdownTimerComponent } from './component/countdown-timer/countdown-timer.component';
// import { CountdownTimerModule } from 'angular-countdown-timer';
import {DialogModule} from 'primeng/dialog';
import {GalleriaModule} from 'primeng/galleria';
import {ButtonModule} from 'primeng/button';
import {SafePipe} from './diractive/safe-url';
import { ConfirmationModalComponent } from './component/confirmation-modal/confirmation-modal.component';
import { MergeImageComponent } from './component/merge-image/merge-image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { CountdownModule, CountdownGlobalConfig } from 'ngx-countdown';
import { AngularCameraComponent } from './component/angular-camera/angular-camera.component'
import {loaderComponent} from './component/loader.component'
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { VerifyHeaderComponent } from './component/verify-header/verify-header.component';
import { LoginComponent } from '../auth/login/login.component';
import {CaptchaModule} from 'primeng/captcha';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { PcheaderComponent } from './component/pcheader/pcheader.component';
import { PcfooterComponent } from './component/pcfooter/pcfooter.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {TooltipModule} from 'primeng/tooltip';
import {CalendarModule} from 'primeng/calendar'
const maskConfig: Partial<IConfig> = {
  validation: false,
};

import { NgxCaptchaModule } from 'ngx-captcha';
import { LoaderComponent } from './loader/loader.component';
import { GMapModule } from 'primeng/gmap';
import { CustomSearchComponent } from './component/custom-search/custom-search.component';
import { VisitComponent } from './component/visit/visit.component';
import { NgInitComponent } from './component/load-directive'


@NgModule({
  declarations: [
    HeaderComponent, 
    CommondivComponent,
    CaptureImageComponent, 
    CountdownTimerComponent,
    SafePipe,
    ConfirmationModalComponent,
    MergeImageComponent,
    loaderComponent,
    AngularCameraComponent,
    VerifyHeaderComponent,
    LoginComponent,
    PcheaderComponent,
    PcfooterComponent,
    // SearchComponent,
    // SignpadComponent,
    LoaderComponent,
    CustomSearchComponent,
    VisitComponent,
    NgInitComponent
  ],
  imports: [
    CommonModule,
    CarouselModule,
    // CountdownTimerModule,
    DialogModule,
    ButtonModule,
    ImageCropperModule,
    RouterModule,
    OverlayPanelModule,
    CountdownModule,
    GalleriaModule,
    NgxMaskModule.forRoot(maskConfig),
    FormsModule,
     ReactiveFormsModule,
     CaptchaModule,
     MessagesModule,
     AutoCompleteModule,
     MessageModule,  
     //AngularSignaturePadModule,
    //  SignaturePadModule,
     TooltipModule,
     NgxCaptchaModule,
     GMapModule,
     CalendarModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  exports:[
    HeaderComponent,
    CommondivComponent,
    CaptureImageComponent,
    CountdownTimerComponent,
    ConfirmationModalComponent,
    SafePipe,
    MergeImageComponent,
    loaderComponent,
    VerifyHeaderComponent,
    LoginComponent,
    PcheaderComponent,
    PcfooterComponent,
    // SearchComponent,
    // SignpadComponent,
    LoaderComponent,
    CustomSearchComponent,
    VisitComponent,
    NgInitComponent
  ],
  providers:[
    CountdownGlobalConfig
  ],
  

  
})


export class ShareModule { }
