
<p class="mt15 dep-title">
  <strong>
    <h3>{{ department_details?.DepartmentName }} 
      <span class="login-text" *ngIf="!userLoginStatus && showLogin>0">Already have an account?<a (click)="openLogin()" >Login Here</a></span>
    </h3>
    <a href="https://www.google.com/search?q={{address}}" target="_blank">
    <span class="ext-addres">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill"
          viewBox="0 0 16 16">
          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
        </svg>
      </div>
      {{department_details?.Address1}} <span class="addres-2">{{department_details?.Address2}}

        {{department_details?.City}} {{department_details?.State}} {{department_details?.ZIP}} </span>
    </span>
  </a>
    <!-- <ul class="department_detail" *ngIf="department_details?.Address2!=''"> -->
    <ul class="department_detail">
    
      <li class="dep-det-op">
        <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-telephone-forward" viewBox="0 0 16 16">
            <path
              d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
          </svg></span><a href="tel:{{ department_details?.PhoneNumber }}">
          {{ department_details?.PhoneNumber | mask: "(000) 000-0000" }}</a>
      </li>
    </ul>

    <div *ngIf="isTemp == true">
      <p class="description">
        <span>
          {{ department_details?.DynamicContent1 }}
        </span>
      </p>
    </div>
  </strong>
</p>


<div *ngIf="isTemp == true">

  <div class="row">
    <div class="col-sm-12" style="padding-right:0;">
      <p-galleria [(value)]="images" [(visible)]="displayCustom" [(activeIndex)]="activeIndex"
      [responsiveOptions]="responsiveOptions" [containerStyle]="{ 'max-width': '850px' }" [numVisible]="7"
      [circular]="true" [fullScreen]="true" [showItemNavigators]="true" [showThumbnails]="false" [baseZIndex]="100000">
      <ng-template pTemplate="item" let-car>
        <img [src]="car.ImageURL" />
      </ng-template>
      <ng-template pTemplate="thumbnail" let-car>
        <div class="p-grid p-nogutter p-justify-center">
          <img [src]="car.ImageURL" style="display: block" />
        </div>
      </ng-template>
    </p-galleria>
  
    
  <div class="banner-carousel booking-cursol owl-carousel owl-theme" *ngIf="screen_size && isslider">
    <ng-container *ngFor="let image of images">
      <div class="car-details" style="background-image:url('{{image.ImageURL}}')"></div>
      </ng-container>
    <!-- <div class="car-details" style="background-image:url('{{image.ImageURL}}')">
          
    </div>  
       -->
  
   </div>
    <!-- <p-carousel class="department-slider" [value]="department_details?.lstImages" styleClass="custom-carousel"
      [numVisible]="2" [numScroll]="1" [circular]="true" [showNavigators]="false" [autoplayInterval]="3000">
      <ng-template pTemplate="item" let-image>
        <div class="car-details" style="background-image:url('{{image.ImageURL}}')">
          
        </div>
      </ng-template>
    </p-carousel> -->
    </div>
    <div class="col-sm-6"  style="padding-left:0;">
      <div  style="width: 90%;margin-left: 2%;">
        <iframe  *ngIf="department_details?.IsGoogleMapVisible" width="100%" height="250" frameborder="2"
          style="border: 0" frameborder="0" style="border: 0" [src]="department_details?.GoogleMapLocationURL | safe"
          allowfullscreen>
        </iframe>
    
      </div>
    </div>
  </div>



 
</div>
