
<!-- <countdown-timer (zeroTrigger)="triggerFunction()"  [end]="startTime"></countdown-timer> -->
<countdown #cd (event)="triggerFunction($event)"  [config]="{'leftTime': 360,'format':'mm:ss'}"></countdown>
<!-- <countdown style="display: none;" #cd (event)="notifify($event)"  [config]="{'leftTime': 300,'format':'mm:ss'}"></countdown> -->

<p-dialog  [closable]="false" modal = "true"
[(visible)]="confirmationModal"
 [style]="{width: '300px'}">
     <p>
        The appointment is being no longer held, please start over the process.
     </p>
    <p-footer>
        <button (click)="onClickcancel();" type="button" class="btn btn-confirm" >{{cancelBtnName}}</button>
    </p-footer>
</p-dialog>




